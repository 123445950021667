import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LoadingSpinner } from '../../core/components/loading/LoadingSpinner';
import { InfoText } from '../../core/components/text/InfoText';
import { Header } from '../../navigation/components/Header';
import { colors } from '../../styles/colors';
import { OnboardingScreenContainer } from '../onboarding/components/OnboardingScreenContainer';
import { verifyAppMail } from './utils/verifyAppMail';

export const AppMailVerificationScreen = () => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<'INVALID_URL' | 'SUCCESS'>();
  const navigation = useNavigate();
  const initialized = useRef(false);
  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;
    verifyAppMail().then((result) => {
      setResponse(result);
    });
  }, []);
  const error = response === 'INVALID_URL';
  const title = error ? t('ONBOARDING-EMAIL-VERIFIED-ERROR-TITLE') : t('ONBOARDING-EMAIL-VERIFIED-SUCCESS-TITLE');
  const text = error ? [t('ONBOARDING-EMAIL-VERIFIED-ERROR-TEXT')] : [t('ONBOARDING-EMAIL-VERIFIED-SUCCESS-TEXT')];
  const handleNext = () => {
    if (error) {
      navigation('../');
    } else {
      navigation('../onboarding/basic-info');
    }
  };
  return (
    <OutletContainer>
      <Header />
      <OnboardingScreenContainer
        hideProgress
        hideBackButton
        nextButton={
          response
            ? {
                onClick: handleNext,
              }
            : undefined
        }
      >
        {!response ? <LoadingSpinner color={colors.Primary} /> : <InfoText headline={title} text={text} />}
      </OnboardingScreenContainer>
    </OutletContainer>
  );
};

const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  padding-top: 100px;
  padding-bottom: 200px;
  background-color: 'red';
`;
