import { Navigate, Outlet } from 'react-router-dom';
import { useOnboardingPracticeInfo } from '../features/onboarding/utils/useOnboardingPracticeInfo';
import { getIsAddNewPractice } from '../store/chainSlice';
import { useMainStore } from '../store/mainStore';

export const PaymentLayout = () => {
  const { practiceId } = useOnboardingPracticeInfo();
  const practiceOnboardingFlags = useMainStore((state) => state.chainData?.onboarding?.onboardingFlags);
  const checkoutComplete = practiceId ? practiceOnboardingFlags?.practices[practiceId]?.checkout : false;
  const isAddNewPractice = useMainStore(getIsAddNewPractice);
  if (!practiceId) {
    return <Navigate to={`/${isAddNewPractice ? 'new-practice' : 'onboarding'}/practices-names`} />;
  } else if (checkoutComplete) {
    return <Navigate to={`/${isAddNewPractice ? 'new-practice' : 'onboarding'}/${practiceId}/payment-status`} />;
  }
  return <Outlet />;
};
