import { Auth, confirmPasswordReset, getAuth, verifyPasswordResetCode } from 'firebase/auth';
import { getParameterByName } from './firebaselVerification';
export const resetPassword = async (newPassword: string) => {
  const mode = getParameterByName('mode');
  const actionCode = getParameterByName('oobCode');

  console.log('mode', mode);
  console.log('actionCode', actionCode);

  const auth = getAuth();
  // Handle the user management action.
  if (!mode || !actionCode) {
    return 'INVALID_URL';
  }
  try {
    if (mode === 'resetPassword') {
      const result = await handlePasswordReset(auth, actionCode, newPassword);
      return result;
    } else {
      return 'INVALID_URL';
    }
  } catch (error: any) {
    console.log('Error:', error);
    return 'INVALID_URL';
  }
};

export const isValidPasswordResetUrl = () => {
  const mode = getParameterByName('mode');
  const actionCode = getParameterByName('oobCode');
  return mode === 'resetPassword' && actionCode;
};

const handlePasswordReset = async (auth: Auth, actionCode: string, newPassword: string) => {
  try {
    await verifyPasswordResetCode(auth, actionCode);
  } catch (error) {
    console.log('Error:', error);
    return 'INVALID_URL';
  }
  try {
    await confirmPasswordReset(auth, actionCode, newPassword);
    return 'SUCCESS';
  } catch (error) {
    console.log('Error:', error);
    return 'INVALID_URL';
  }
};
