import { isEmpty } from 'lodash';
import {
  getChainOnboardingPaymentType,
  getChainPractices,
  getIsAddNewPractice,
  getOnboardingFlags,
} from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { OnboardingFlags } from '../../../types/Practices/ChainType';
import { ProgressStep } from '../components/OnboardingProgressButton';

export const generatePracticeProgress = (
  practices:
    | {
        name: string;
        id: string;
      }[]
    | undefined,
  onboardingFlags: OnboardingFlags | undefined,
  currentUrl: string,
  samePayment: boolean,
) => {
  const state = useMainStore.getState();
  const isAddNewPractice = getIsAddNewPractice(state);
  const generateArray = (praxisName: string | undefined, practiceId: string | undefined, index: number | undefined) => {
    const previousPracticeId = index ? practices?.[index - 1]?.id : undefined;
    const practicesFlags = onboardingFlags?.practices;
    const isPaymentTypeScreen = index === 0 && currentUrl.includes('payment-type');
    const hidden = Boolean(
      practiceId &&
        !currentUrl.includes(practiceId) &&
        !isPaymentTypeScreen &&
        praxisName &&
        practices &&
        practices.length > 1,
    );
    const basePath = isAddNewPractice ? `/new-practice/${practiceId}` : `/onboarding/${practiceId}`;
    const previousPracticeComplete = previousPracticeId
      ? practicesFlags?.[previousPracticeId]?.checkout || samePayment
      : true;

    return [
      {
        title: praxisName && practices && practices.length > 1 ? praxisName : 'Deine Praxis',
        depth: 0,
        completed: Boolean(practiceId && practicesFlags && previousPracticeComplete),
        active: currentUrl.includes(`${basePath}/practice-data-info`),
      },
      {
        title: 'Vergütung',
        depth: 1,
        completed: Boolean(practiceId && practicesFlags?.[practiceId]?.info),
        active: currentUrl.includes(`${basePath}/practice-payout-data`),
        hidden,
      },
      {
        title: 'Praxis Team',
        depth: 1,
        completed: Boolean(practiceId && practicesFlags?.[practiceId]?.payoutData),
        active: currentUrl.includes(`${basePath}/practice-therapists`),
        hidden,
      },
      {
        title: 'Ansprechpartner',
        depth: 1,
        completed: Boolean(practiceId && practicesFlags?.[practiceId]?.therapists),
        active: currentUrl.includes(`${basePath}/practice-contact`),
        hidden,
      },
      {
        title: 'Starter Paket',
        depth: 1,
        completed: Boolean(practiceId && practicesFlags?.[practiceId]?.contactPerson),
        active: currentUrl.includes(`${basePath}/practice-starter`),
        hidden,
      },
      {
        title: 'Physiofit Abo',
        depth: 1,
        completed: Boolean(practiceId && practicesFlags?.[practiceId]?.starterPack),
        active:
          currentUrl.includes(`${basePath}/invoice-address`) ||
          (index === 0 && currentUrl.includes('payment-type')) ||
          currentUrl.includes(`${basePath}/payment-status`),
        hidden,
      },
    ];
  };
  if (!practices || (practices && isEmpty(practices))) return generateArray(undefined, undefined, undefined);
  const result = practices
    .map((practice, index) => {
      return generateArray(practice.name, practice.id, index);
    })
    .flat();
  return result;
};

export const generateOnboardingProgressData = () => {
  const onboardingFlags = useMainStore(getOnboardingFlags);
  const practices = useMainStore(getChainPractices);
  const currentUrl = window.location.pathname;
  const samePayment = useMainStore(getChainOnboardingPaymentType) === 'same';
  const practiceProgress = generatePracticeProgress(practices, onboardingFlags, currentUrl, samePayment);

  const mainProgress = [
    {
      title: 'Dein Konto',
      depth: 0,
      completed: onboardingFlags?.basicInfo,
      active:
        currentUrl.includes('basic-info') || currentUrl.includes('referral') || currentUrl.includes('practices-names'),
    },
    ...practiceProgress,
  ] as ProgressStep[];
  return mainProgress;
};
