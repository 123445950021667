import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LoadingSpinner } from '../../../core/components/loading/LoadingSpinner.tsx';
import { InfoText } from '../../../core/components/text/InfoText.tsx';
import { Header } from '../../../navigation/components/Header.tsx';
import { colors } from '../../../styles/colors.ts';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { readUrlVerification } from '../utils/firebaselVerification.ts';

export const EmailChangeScreen = () => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<'INVALID_URL' | 'SUCCESS'>();
  const navigation = useNavigate();
  const initialized = useRef(false);
  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;
    readUrlVerification().then((newResponse) => {
      if (response === undefined) {
        setResponse(newResponse.status);
      }
    });
  }, []);
  const error = response === 'INVALID_URL';
  const title = error ? t('ONBOARDING-EMAIL-VERIFIED-ERROR-TITLE') : t('ONBOARDING-EMAIL-CHANGE-SUCCESS-TITLE');
  const text = error ? [t('ONBOARDING-EMAIL-VERIFIED-ERROR-TEXT')] : [t('ONBOARDING-EMAIL-CHANGE-SUCCESS-TEXT')];
  const handleNext = () => {
    navigation('../');
  };
  return (
    <OutletContainer>
      <Header />
      <OnboardingScreenContainer
        hideProgress
        hideBackButton
        nextButton={
          response
            ? {
                onClick: handleNext,
              }
            : undefined
        }
      >
        {!response ? <LoadingSpinner color={colors.Primary} /> : <InfoText headline={title} text={text} />}
      </OnboardingScreenContainer>
    </OutletContainer>
  );
};

const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  padding-top: 100px;
  padding-bottom: 200px;
  background-color: 'red';
`;
