import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { Body } from '../../../styles/textStyles';
import { CustomButton } from '../buttons/CustomButton';

type Props = {
  text: string;
  selected: boolean;
  onClick: () => void;
};

export const TagButton = ({ text, selected, onClick }: Props) => {
  return (
    <StyledButton selected={selected} onClick={onClick}>
      <ColoredBody selected={selected}>{text}</ColoredBody>
    </StyledButton>
  );
};

const StyledButton = styled(CustomButton)<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.selected ? colors.Primary : colors.Light)};
  border-radius: 12px;
  padding: 10px 15px;
`;

const ColoredBody = styled(Body)<{ selected: boolean }>`
  margin: 0;
  color: ${(props) => (props.selected ? colors.White : colors.Dark)};
`;
