import { StateCreator } from 'zustand';
import { ContentUpdateInfo } from '../types/App/ContentUpdateInfoType';

export type EventIndicatorType = { type: EventIndicatorStateType; message: string; index: number } | undefined;
export type EventIndicatorStateType = 'error' | 'success' | 'info' | 'warning';

export type AppState = {
  contentUpdateInfo?: ContentUpdateInfo;
  eventIndicatorData?: EventIndicatorType;
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  setEventIndicatorData: (type: EventIndicatorStateType, message: string) => void;
  setContentUpdateInfo: (_contentUpdateInfo?: ContentUpdateInfo) => void;
  resetAppState: () => void;
};

const initialState = {
  contentUpdateInfo: undefined,
  eventIndicatorData: undefined,
  drawerOpen: true,
};

export const createAppSlice: StateCreator<AppState> = (set) => ({
  ...initialState,
  setEventIndicatorData: (type: EventIndicatorStateType, message: string) =>
    set((state: AppState) => {
      state.eventIndicatorData = {
        type,
        message,
        index: state.eventIndicatorData?.index ? state.eventIndicatorData?.index + 1 : 1,
      };
      return state;
    }),
  setDrawerOpen: (open: boolean) => set({ drawerOpen: open }),
  setContentUpdateInfo: (contentUpdateInfo?: ContentUpdateInfo) => set({ contentUpdateInfo: contentUpdateInfo }),
  resetAppState: () => set(initialState),
});

export const getContentUpdateInfo = (state: AppState) => state.contentUpdateInfo;
export const getEventIndicatorData = (state: AppState) => state.eventIndicatorData;
export const getDrawerOpen = (state: AppState) => state.drawerOpen;
