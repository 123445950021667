import { doc, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ButtonGroup } from '../../../core/components/buttons/ButtonGroup';
import { ModalContainer } from '../../../core/components/container/ModalContainer';

import styled from 'styled-components';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { Body, H3 } from '../../../styles/textStyles';

export const TherapistSettingsDisableModal = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const therapist = useLocation()?.state?.therapist;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const isInactive = therapist?.inactive;

  const handleClose = () => {
    navigate('..');
  };

  const disableTherapist = async () => {
    setLoading(true);
    try {
      await updateDoc(doc(db, 'therapists/' + therapist.id), {
        inactive: !isInactive,
      });
      setEventIndicator('success', isInactive ? 'Therapeut aktiviert' : 'Therapeut deaktiviert');
      handleClose();
    } catch (error) {
      setEventIndicator('error', `Fehler beim ${isInactive ? 'Aktivieren' : 'Deaktivieren'} des Therapeuten`);
      console.error('Error writing document: ', error);
    } finally {
      setLoading(false);
    }
  };

  if (!therapist) {
    return <Navigate to=".." />;
  }
  return (
    <ModalContainer handleClose={handleClose}>
      <Container>
        <StyledH3>{isInactive ? 'Therapeut aktivieren' : 'Therapeut deaktivieren'}</StyledH3>
        <StyledBody>
          {!isInactive
            ? 'Der persönliche Link von deaktivierten Nutzern funktioniert nach der Deaktivierung nicht mehr. Weiterhin können deaktivierte Nutzer keine Patienten mehr einladen oder die Einladungshistorie einsehen. '
            : 'Das Konto des Therapeuten wird wieder aktiviert. Der persönliche Link des Therapeuten funktioniert wieder und der Therapeut kann wieder Patienten einladen und die Einladungshistorie einsehen. '}
        </StyledBody>
        <ButtonGroup
          textButton={{
            text: 'Abbrechen',
            onClick: handleClose,
            loading,
          }}
          progressButton={{
            currentStep: 0,
            numberOfSteps: 0,
            text: isInactive ? 'Jetzt Aktivieren' : 'Jetzt Deaktivieren',
            onClick: disableTherapist,
            loading,
          }}
        />
      </Container>
    </ModalContainer>
  );
};

const StyledH3 = styled(H3)`
  color: ${colors.Medium};
`;

const StyledBody = styled(Body)`
  color: ${colors.Medium};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 520px;
`;
