import { doc, getDoc } from 'firebase/firestore';
import { useState } from 'react';
import { db } from '../../../firebaseConfig';
import { PracticeInvoiceData } from '../../../types/Practices/PracticeInvoiceDataType';
import { PracticePayout } from '../../../types/Practices/PracticePayoutType';

export const useHandleTransferInvoicePracticeData = (
  practiceId: string,
  setCurrentStep: (value: number) => void,
  invoiceInfo: undefined | PracticeInvoiceData,
) => {
  const [toggleActive, setToggleActive] = useState(invoiceInfo?.onboardingCopy ?? false);

  const [fields, setFieldsInternal] = useState({
    practiceName: invoiceInfo?.address?.name ?? '',
    street: invoiceInfo?.address?.street ?? '',
    houseNumber: invoiceInfo?.address?.houseNumber ?? '',
    cityCode: invoiceInfo?.address?.cityCode ?? '',
    city: invoiceInfo?.address?.city ?? '',
    taxId: invoiceInfo?.taxId ?? '',
  });

  const setFields = (newFields: { [key: string]: any }) => {
    setToggleActive(false);
    setFieldsInternal({ ...fields, ...newFields });
  };
  const handleToggle = async () => {
    if (!toggleActive) {
      setToggleActive(true);
      const payoutData = (await getDoc(doc(db, 'practices/' + practiceId + '/documents/payoutData'))).data() as
        | undefined
        | PracticePayout;
      if (payoutData) {
        const payoutAddress = payoutData.payoutAddress;
        setFieldsInternal({
          practiceName: payoutData?.practiceName ?? '',
          street: payoutAddress?.street ?? '',
          houseNumber: payoutAddress?.houseNumber ?? '',
          cityCode: payoutAddress?.cityCode ?? '',
          city: payoutAddress?.city ?? '',
          taxId: payoutData?.taxId ?? '',
        });
      }
    } else {
      setCurrentStep(0);
      setToggleActive(false);
      setFieldsInternal({
        practiceName: '',
        street: '',
        houseNumber: '',
        cityCode: '',
        city: '',
        taxId: '',
      });
    }
  };

  return { toggleActive, handleToggle, fields, setFields };
};
