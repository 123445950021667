import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../../firebaseConfig';
import { PracticePayout } from '../../../types/Practices/PracticePayoutType';

export const usePracticePayoutConnect = (practiceId?: string) => {
  const [practicePayout, setPracticePayout] = useState<PracticePayout>();
  useEffect(() => {
    if (!practiceId) return;
    const sub = onSnapshot(doc(db, 'practices/' + practiceId + '/documents/payoutData'), (practiceSnapshot) => {
      const practiceData = practiceSnapshot.data() as PracticePayout;
      setPracticePayout(practiceData);
    });
    return sub;
  }, [practiceId]);

  return {
    loadingPracticePayout: practicePayout === undefined,
    practicePayout,
  };
};
