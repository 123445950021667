import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import firebaseKeys from '../keys/firebaseKeys.ts';

const env = import.meta.env.VITE_ENV ?? 'production';
console.log('env:', env);
const firebaseConfig = firebaseKeys[env];

const app = initializeApp(firebaseConfig);
const functions = getFunctions();
functions.region = 'europe-west1';
export const db = getFirestore(app);
