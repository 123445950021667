import { isEqual } from 'lodash';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createWithEqualityFn } from 'zustand/traditional';
import { AppState, createAppSlice } from './appSlice';
import { ChainState, createChainSlice } from './chainSlice';
import { createInvitationSlice, InvitationState } from './invitationSlice';
import { createUserSlice, UserState } from './userSlice';

export type State = AppState & InvitationState & UserState & ChainState;

export const useMainStore = createWithEqualityFn<State>()(
  immer(
    devtools(
      persist(
        (...a) => ({
          ...createAppSlice(...a),
          ...createInvitationSlice(...a),
          ...createUserSlice(...a),
          ...createChainSlice(...a),
        }),
        {
          name: 'store',
        },
      ),
    ),
  ),
  isEqual,
);

export const resetStore = (state: State) => {
  state.resetAppState();
  state.resetInvitationState();
  state.resetUserState();
  state.resetChainState();
};
