import { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { GenericSvg } from '../../assets/svgs/GenericSVG';
import { getEventIndicatorData } from '../../store/appSlice';
import { useMainStore } from '../../store/mainStore';
import { colors } from '../../styles/colors';
import { Body } from '../../styles/textStyles';

export const EventIndicator = () => {
  const eventIndicatorData = useMainStore(getEventIndicatorData);

  const [isVisible, setIsVisible] = useState(false);
  const [currentEventIndex, setCurrentEventIndex] = useState(eventIndicatorData?.index ?? 0);
  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!eventIndicatorData?.index || eventIndicatorData?.index === currentEventIndex) return;
    timeout.current && clearTimeout(timeout.current);
    setCurrentEventIndex(eventIndicatorData?.index ?? 0);
    setIsVisible(true);
    timeout.current = setTimeout(() => {
      setIsVisible(false);
    }, 3000);
  }, [eventIndicatorData?.index, currentEventIndex]);

  const handleClick = () => {
    setIsVisible(false);
  };

  const isError = eventIndicatorData?.type === 'error';

  if (!eventIndicatorData) return null;

  return (
    <IndicatorContainer $isError={isError} $isVisible={isVisible} onClick={handleClick}>
      <StyledBody>{eventIndicatorData.message}</StyledBody>
      <GenericSvg name={isError ? 'Close' : 'Checkmark'} height={24} primaryColor={colors.White} />
    </IndicatorContainer>
  );
};

const StyledBody = styled(Body)`
  color: ${colors.White};
`;

const slideInUp = keyframes`
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
`;

const slideOutDown = keyframes`
  from {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  to {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
`;

const IndicatorContainer = styled.div<{ $isError: boolean; $isVisible: boolean }>`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${(props) => (props.$isError ? colors.LightRed : colors.Primary)};
  padding: 10px 20px;
  border-radius: 10px;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: ${(props) => (props.$isVisible ? 'visible' : 'hidden')};
  transition: visibility 0.5s;

  ${(props) =>
    props.$isVisible
      ? css`
          animation: ${slideInUp} 0.5s ease-in-out forwards;
        `
      : css`
          animation: ${slideOutDown} 0.5s ease-in-out forwards;
        `}
`;
