import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { useHandleInformationForm } from '../utils/useHandleInformationForm';

type Props = {
  fields: {
    firstName: string;
    lastName: string;
  };
  setFields: (value: { firstName: string; lastName: string }) => void;
};

export const PracticeTherapistInformation = ({ fields, setFields }: Props) => {
  const { handleInput } = useHandleInformationForm(fields, setFields, undefined);
  const { firstName, lastName } = fields;
  const { t } = useTranslation();

  return (
    <HorizontalContainer>
      <SingleTextInput
        id="firstInput"
        value={firstName}
        onChange={(value) => handleInput('firstName', value)}
        placeholder={t('ONBOARDING-PRACTICE-THERAPISTS-SCREEN-THERAPIST-NAME')}
        noPasswordManager
      />
      <SingleTextInput
        value={lastName}
        onChange={(value) => handleInput('lastName', value)}
        placeholder={t('ONBOARDING-PRACTICE-THERAPISTS-SCREEN-THERAPIST-SURNAME')}
        noPasswordManager
      />
    </HorizontalContainer>
  );
};

const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
  width: 100%;
`;
