import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { useMainStore } from '../../store/mainStore';

export const useHandleAuthState = () => {
  useEffect(() => {
    const auth = getAuth();
    const state = useMainStore.getState();
    const sub = onAuthStateChanged(auth, (user) => {
      if (user) {
        state.setAuthData(user.uid, user.emailVerified, user.email ?? undefined);
      } else {
        state.setAuthData();
      }
    });
    return () => sub();
  }, []);
};
