import { Navigate, Outlet } from 'react-router-dom';
import { ScreenContainer } from '../core/components/container/ScreenContainer';

import styled from 'styled-components';
import { VersionTag } from '../core/components/VersionTag';
import { getOnboardingCompleted } from '../store/chainSlice';
import { useMainStore } from '../store/mainStore';
import { Header } from './components/Header';
import { useScrollToTop } from './utils/useScrollToTop';

export const OnboardingLayout = () => {
  // useOnboardingRedirect();
  useScrollToTop();
  const onboardingCompleted = useMainStore(getOnboardingCompleted);
  if (onboardingCompleted) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <ScreenContainer>
      <Header />
      <OutletContainer className="onBoardingScreenContainer-scroll-ancestor">
        <Outlet />
      </OutletContainer>
      <VersionTag />
    </ScreenContainer>
  );
};

const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 100px;
  background-color: 'red';
`;
