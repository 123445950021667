import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { IntercomProvider } from 'react-use-intercom';
import App from './App.tsx';
import { ErrorOverlay } from './features/sentry/screens/ErrorOverlay.tsx';
import { useSentry } from './features/sentry/utils/useSentry.ts';

useSentry();

// posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY, {
//   api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
// });

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={() => <ErrorOverlay type="Error" />}>
      <IntercomProvider autoBoot appId={import.meta.env.VITE_INTERCOM_ENV}>
        {/* <PostHogProvider client={posthog} options={{ autocapture: true }}> */}
        <App />
        {/* </PostHogProvider> */}
      </IntercomProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
