import { doc, getDoc } from 'firebase/firestore';
import { useState } from 'react';
import { db } from '../../../firebaseConfig';
import { getChainPractices } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { PracticePayout } from '../../../types/Practices/PracticePayoutType';

export const useHandleTransferPracticeData = (
  practiceIndex: number,
  transferFunc: (data: any) => void,
  clearData: () => void,
  initialCopyId?: string,
) => {
  const [toggleActive, setToggleActive] = useState(initialCopyId ? true : false);
  const [selectedTransferPractice, setTransferPractice] = useState<string | undefined>(initialCopyId);
  const practices = useMainStore(getChainPractices);

  const handleToggle = () => {
    if (toggleActive) {
      setSelectedTransferPractice(undefined);
    } else {
      setSelectedTransferPractice(practices?.[0]?.id);
    }
    setToggleActive((prev) => !prev);
  };

  const setToggleInactive = () => {
    setToggleActive(false);
  };

  const practicesToTransfer = practices?.slice(0, practiceIndex);

  const transferData = async (id: string) => {
    const data = (await getDoc(doc(db, 'practices/' + id + '/documents/payoutData')))?.data() as PracticePayout;
    transferFunc(data);
  };

  const setSelectedTransferPractice = (value?: string) => {
    if (!value) {
      clearData();
    } else {
      transferData(value);
    }
    setTransferPractice(value);
  };

  const showToggle = practiceIndex > 0;
  return {
    showToggle,
    toggleActive,
    handleToggle,
    practicesToTransfer,
    selectedTransferPractice,
    setSelectedTransferPractice,
    transferData,
    setToggleInactive,
  };
};
