type Colors = {
  Primary: string;
  Medium: string;
  Dark: string;
  Light: string;
  Background: string;
  White: string;
  Success: string;
  LightRed: string;
};

export const colors: Colors = {
  Primary: '#00B8E1',
  Medium: '#607F90',
  Dark: '#1E3542',
  Light: '#EFF1F1',
  White: '#FFFFFF',
  Background: '#FCFDFD',
  LightRed: '#D65A56',
  Success: '#3CBB00',
};
