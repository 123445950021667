import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonGroup } from '../../../core/components/buttons/ButtonGroup';
import { ModalContainer } from '../../../core/components/container/ModalContainer';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { H3 } from '../../../styles/textStyles';

export const ChangePasswordModal = () => {
  const { t } = useTranslation();
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);

  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorCurrentPassword, setErrorCurrentPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('..');
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (newPassword.length < 8) {
        setErrorPassword(t('ONBOARDING-REGISTER-ERROR-PASSWORD-SHORT'));
        return;
      }
      const auth = getAuth();
      const user = auth.currentUser;
      const currentEmail = user?.email;
      if (!currentEmail) {
        return;
      }
      const credential = EmailAuthProvider.credential(currentEmail, currentPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      setEventIndicator('success', t('CHANGE-PASSWORD-SUCCESS'));
      navigate('..');
    } catch (e: any) {
      if (e.code === 'auth/invalid-credential') {
        setErrorCurrentPassword(t('CHANGE-PASSWORD-ERROR-WRONG-CURRENT'));
      } else {
        setErrorPassword(t('ONBOARDING-ERROR-PASSWORD-RESET-GENERAL'));
      }
      setEventIndicator('error', t('CHANGE-PASSWORD-ERROR'));
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const currentStep = [currentPassword, newPassword].filter((value) => value !== '').length;

  return (
    <ModalContainer handleClose={handleClose}>
      <Container>
        <StyledH3>{t('CHANGE-PASSWORD-BUTTON')}</StyledH3>
        <FormContainer>
          <SingleTextInput
            id="firstInput"
            value={currentPassword}
            onChange={setCurrentPassword}
            placeholder={t('CURRENT-PASSWORD-PLACEHOLDER')}
            type="password"
            error={errorCurrentPassword}
          />
          <SingleTextInput
            id="secondInput"
            value={newPassword}
            onChange={setNewPassword}
            placeholder={t('NEW-PASSWORD-PLACEHOLDER')}
            error={errorPassword}
            type="password"
          />
        </FormContainer>
        <ButtonGroup
          progressButton={{
            text: t('CHANGE-PASSWORD-BUTTON'),
            onClick: handleSubmit,
            numberOfSteps: 2,
            currentStep: currentStep,
            loading: loading,
          }}
          textButton={{
            text: t('BACK-BUTTON'),
            onClick: handleClose,
            loading: loading,
          }}
        />
      </Container>
    </ModalContainer>
  );
};

const StyledH3 = styled(H3)`
  color: ${colors.Dark};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 520px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
