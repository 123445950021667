import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect } from 'react';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { getFirebaseUserId } from '../../../store/userSlice';
import { PracticeUser } from '../../../types/WebUser/PracticeUserType';

export const useUserConnect = () => {
  const setUserData = useMainStore((state) => state.setUserData);
  const userId = useMainStore(getFirebaseUserId);
  useEffect(() => {
    console.log('userId', userId);
    if (!userId) return;
    const subscription = onSnapshot(doc(db, 'practiceUsers/' + userId), (snapshot) => {
      if (snapshot?.data()) {
        setUserData(snapshot.data() as PracticeUser);
      }
    });
    return () => subscription();
  }, [setUserData, userId]);
};
