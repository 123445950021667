/* eslint-disable import/no-unresolved */ /*This component is generated automatically by generateAssets.py. Do not edit it manually. 
The component getAsset() helps us to use our theme on the svgs and make importing easier.
To update please run `npm run generateAssets.ts`*/
import { colors } from '../../styles/colors';
import ArrowDown from './ArrowDown.svg?react';
import ArrowLeft from './ArrowLeft.svg?react';
import ArrowRightSmall from './ArrowRightSmall.svg?react';
import ArrowUp from './ArrowUp.svg?react';
import Burger from './Burger.svg?react';
import Chat from './Chat.svg?react';
import CheckBoxRoundActive from './CheckBoxRoundActive.svg?react';
import CheckBoxRoundInactive from './CheckBoxRoundInactive.svg?react';
import CheckBoxRoundPrimary from './CheckBoxRoundPrimary.svg?react';
import Checkmark from './Checkmark.svg?react';
import CheckmarkWithBackground from './CheckmarkWithBackground.svg?react';
import Close from './Close.svg?react';
import DashboardPreview from './DashboardPreview.svg?react';
import EyeClosed from './EyeClosed.svg?react';
import EyeOpen from './EyeOpen.svg?react';
import FailureIcon from './FailureIcon.svg?react';
import LogoWide from './LogoWide.svg?react';
import Logout from './Logout.svg?react';
import Minus from './Minus.svg?react';
import OpenPDF from './OpenPDF.svg?react';
import Plus from './Plus.svg?react';
import SafariPlus from './SafariPlus.svg?react';
import SafariShare from './SafariShare.svg?react';

export type SVGName =
  | 'ArrowDown'
  | 'ArrowLeft'
  | 'ArrowRightSmall'
  | 'ArrowUp'
  | 'Burger'
  | 'Chat'
  | 'CheckBoxRoundActive'
  | 'CheckBoxRoundInactive'
  | 'CheckBoxRoundPrimary'
  | 'Checkmark'
  | 'CheckmarkWithBackground'
  | 'Close'
  | 'DashboardPreview'
  | 'EyeClosed'
  | 'EyeOpen'
  | 'FailureIcon'
  | 'LogoWide'
  | 'Logout'
  | 'Minus'
  | 'OpenPDF'
  | 'Plus'
  | 'SafariPlus'
  | 'SafariShare';

type SvgProps = {
  [key: string]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
      primary?: string | undefined;
      secondary?: string | undefined;
    }
  >;
};
const svgs: SvgProps = {
  ArrowDown,
  ArrowLeft,
  ArrowRightSmall,
  ArrowUp,
  Burger,
  Chat,
  CheckBoxRoundActive,
  CheckBoxRoundInactive,
  CheckBoxRoundPrimary,
  Checkmark,
  CheckmarkWithBackground,
  Close,
  DashboardPreview,
  EyeClosed,
  EyeOpen,
  FailureIcon,
  LogoWide,
  Logout,
  Minus,
  OpenPDF,
  Plus,
  SafariPlus,
  SafariShare,
};

type Props = React.SVGProps<SVGSVGElement> & {
  name: SVGName;
  primaryColor?: string;
  secondaryColor?: string;
  size?: number;
};
export const GenericSvg = (props: Props) => {
  const Icon = svgs[props.name as SVGName];
  // https://stackoverflow.com/questions/49358560/react-wrapper-react-does-not-recognize-the-staticcontext-prop-on-a-dom-elemen
  const { name, primaryColor, secondaryColor, size, ...rest } = props;
  return (
    <div>
      <Icon
        {...rest}
        primary={primaryColor ?? colors.Dark}
        secondary={secondaryColor ?? colors.Light}
        height={size ?? props.height}
        width={size ?? props.width}
      />
    </div>
  );
};
