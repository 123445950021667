import styled from 'styled-components';
import { GenericSvg } from '../../../assets/svgs/GenericSVG';
import { colors } from '../../../styles/colors';
import { Body } from '../../../styles/textStyles';
import { LoadingSpinner } from './LoadingSpinner';

type Props = {
  loading: boolean;
  text: string;
};

export const ConfirmationWaiting = ({ text, loading }: Props) => {
  return (
    <Container loading={loading}>
      <StyledBody>{text}</StyledBody>
      {loading ? (
        <LoadingSpinner color="white" />
      ) : (
        <GenericSvg name="Checkmark" height={24} primaryColor={colors.White} />
      )}
    </Container>
  );
};

const StyledBody = styled(Body)`
  color: ${colors.White};
`;

const Container = styled.div<{ loading: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ loading }) => (loading ? colors.Medium : colors.Primary)};
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  font-family: sans-serif;
  width: 100%;
  align-items: center;
  gap: 10px;
`;
