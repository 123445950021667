import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';

type Props = {
  fields: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  handleInput: (key: string, value: string) => void;
  loadingData?: boolean;
};

export const PracticeContactForm = ({ fields, handleInput, loadingData = false }: Props) => {
  const { t } = useTranslation();
  const { firstName, lastName, email, phoneNumber } = fields;

  return (
    <FormContainer>
      <HorizontalContainer>
        <SingleTextInput
          loadingData={loadingData}
          id="firstInput"
          value={firstName}
          onChange={(value) => handleInput('firstName', value)}
          placeholder={t('ONBOARDING-PRACTICE-CONTACT-PARTNER-FIRSTNAME')}
        />
        <SingleTextInput
          loadingData={loadingData}
          value={lastName}
          onChange={(value) => handleInput('lastName', value)}
          placeholder={t('ONBOARDING-PRACTICE-CONTACT-PARTNER-LASTNAME')}
        />
      </HorizontalContainer>
      <SingleTextInput
        loadingData={loadingData}
        value={email}
        onChange={(value) => handleInput('email', value)}
        placeholder={t('ONBOARDING-PRACTICE-CONTACT-PARTNER-EMAIL')}
      />
      <SingleTextInput
        loadingData={loadingData}
        value={phoneNumber}
        onChange={(value) => handleInput('phoneNumber', value)}
        placeholder={t('ONBOARDING-PRACTICE-CONTACT-PARTNER-PHONE')}
      />
    </FormContainer>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
`;
