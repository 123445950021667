import { getInvitationId } from '../../../store/invitationSlice';
import { useMainStore } from '../../../store/mainStore';

export const useCheckForInvitationId = () => {
  const currentInvitationId = useMainStore(getInvitationId);
  const queryParameters = new URLSearchParams(window.location.search);
  const newInvitationId = queryParameters.get('id');

  return { hasInvitation: Boolean(currentInvitationId || newInvitationId) };
};
