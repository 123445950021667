import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { TextButton } from '../../../core/components/buttons/TextButton';
import { db } from '../../../firebaseConfig';
import { getChainPractices } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { H3 } from '../../../styles/textStyles';
import { PracticePackageInfo } from '../../../types/Practices/PracticePackageInfoType';

export const DHLTrackingButtons = () => {
  const practices = useMainStore(getChainPractices);
  const [shippingInfos, setShippingInfos] = useState<{ name: string; id: string }[]>();
  useEffect(() => {
    if (!practices) return;
    const loadShippingInfos = async () => {
      const promises = practices.map(async (practice) => {
        const data = (
          await getDoc(doc(db, 'practices/' + practice.id + '/documents/practicePackageInfo'))
        ).data() as PracticePackageInfo;
        return { name: data.practiceName, id: data.starterPackage?.shipmentId ?? '' };
      });
      const shippingInfos = await Promise.all(promises);
      setShippingInfos(shippingInfos);
    };
    loadShippingInfos();
  }, [practices]);
  const openShipmentStatusTab = (id) => {
    window.open('https://www.dhl.de/de/privatkunden/dhl-sendungsverfolgung.html?piececode=' + id, '_blank');
  };

  return (
    <ContentContainer>
      <StyledH3>Starter Paket versendet an:</StyledH3>
      {shippingInfos?.map((shippingInfo) => (
        <TextButton
          key={shippingInfo.id}
          text={shippingInfo.name}
          color={colors.White}
          onClick={() => openShipmentStatusTab(shippingInfo.id)}
        />
      ))}
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledH3 = styled(H3)`
  color: ${colors.White};
  margin-bottom: 10px;
`;
