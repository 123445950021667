import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { GenericSvg } from '../../../assets/svgs/GenericSVG';
import { CustomButton } from '../../../core/components/buttons/CustomButton';
import { InfoText } from '../../../core/components/text/InfoText';
import { setChainOnboardingFlag } from '../../../core/utils/editFirestore/editChain';
import { editOwner } from '../../../core/utils/editFirestore/editOwner';
import { useMainStore } from '../../../store/mainStore';
import { getUserReferralOrigin } from '../../../store/userSlice';
import { colors } from '../../../styles/colors';
import { H4 } from '../../../styles/textStyles';
import { ReferralOptions } from '../../../types/WebUser/PracticeUserType';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';

const referralOptions = [
  {
    value: ReferralOptions.socialMedia,
    title: 'ONBOARDING-REFERRAL-SOCIAL-MEDIA',
  },
  {
    value: ReferralOptions.recommendation,
    title: 'ONBOARDING-REFERRAL-RECOMMENDATION',
  },
  {
    value: ReferralOptions.training,
    title: 'ONBOARDING-REFERRAL-TRAINING',
  },
  {
    value: ReferralOptions.fair,
    title: 'ONBOARDING-REFERRAL-FAIR',
  },
  {
    value: ReferralOptions.google,
    title: 'ONBOARDING-REFERRAL-GOOGLE',
  },
  {
    value: ReferralOptions.call,
    title: 'ONBOARDING-REFERRAL-PHONE',
  },
  {
    value: ReferralOptions.walkIn,
    title: 'ONBOARDING-REFERRAL-PRACTICE',
  },
  {
    value: ReferralOptions.other,
    title: 'ONBOARDING-REFERRAL-OTHER',
  },
];

export const OnboardingReferralScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const initialValue = useMainStore(getUserReferralOrigin);
  const [chosenOption, setChosenOption] = useState(initialValue ?? '');
  const [loading, setLoading] = useState(false);
  const handleNext = async () => {
    setLoading(true);
    try {
      await editOwner({ ['onboardingInfo.referralOrigin']: chosenOption });
      await setChainOnboardingFlag(`referral`, 'referral');
      navigate('../practices-names');
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <OnboardingScreenContainer
      nextButton={{
        currentStep: chosenOption !== '' ? 1 : 0,
        numberOfSteps: 1,
        onClick: handleNext,
        loading,
      }}
    >
      <InfoText headline={t('ONBOARDING-REFERRAL-TITLE')} />
      <OptionsContainer>
        {referralOptions.map((option) => (
          <CustomButton key={option.value} onClick={() => setChosenOption(option.value)}>
            <ReferralOptionContainer key={option.value}>
              <GenericSvg
                size={20}
                name={chosenOption === option.value ? 'CheckBoxRoundActive' : 'CheckBoxRoundInactive'}
                primaryColor={chosenOption === option.value ? colors.Primary : colors.Medium}
              />
              <StyledH4>{t(option.title)}</StyledH4>
            </ReferralOptionContainer>
          </CustomButton>
        ))}
      </OptionsContainer>
    </OnboardingScreenContainer>
  );
};

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ReferralOptionContainer = styled.div`
  padding-left: 14px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 34px;
`;

const StyledH4 = styled(H4)`
  color: ${colors.Medium};
  text-align: left;
`;
