import { ReactNode, useRef } from 'react';
import styled, { css } from 'styled-components';
import { DESKTOP_BREAKPOINT } from '../../../styles/breakpoints';
import { colors } from '../../../styles/colors';

type Props = {
  children: ReactNode;
  customContentStyles?: ReturnType<typeof css>;
  handleClose?: () => void;
};
export const ModalContainer = ({ children, customContentStyles, handleClose }: Props) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (overlayRef.current && event.target === overlayRef.current) {
      handleClose?.();
    }
  };

  return (
    <ModalOverlay ref={overlayRef} onClick={handleOverlayClick}>
      <ModalContent $customStyles={customContentStyles}>{children}</ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const ModalContent = styled.div<{ $customStyles?: ReturnType<typeof css> }>`
  background-color: ${colors.Background};
  padding: 30px 30px 10px 30px;
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  ${({ $customStyles }) => $customStyles}
`;
