// MainLayout.js
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { ScreenContainer } from '../core/components/container/ScreenContainer';
import { getIncompleteChainPractices, getIsAddNewPractice, getOnboardingCompleted } from '../store/chainSlice';
import { useMainStore } from '../store/mainStore';

import { VersionTag } from '../core/components/VersionTag';
import { useWindowDimensions } from '../core/utils/useWindowDimensions';
import { DESKTOP_BREAKPOINT, DESKTOP_BREAKPOINT_NUMBER } from '../styles/breakpoints';
import { Drawer } from './components/Drawer/Drawer';
import { Header } from './components/Header';

export const DrawerLayout = () => {
  const { width } = useWindowDimensions();
  const drawerAlwaysOpen = width > DESKTOP_BREAKPOINT_NUMBER;
  const isAddNewPractice = useMainStore(getIsAddNewPractice);
  const incompletePractices = useMainStore(getIncompleteChainPractices);

  const [isDrawerOpen, setIsDrawerOpen] = useState(drawerAlwaysOpen);

  useEffect(() => {
    setIsDrawerOpen(drawerAlwaysOpen);
  }, [width]);

  const onboardingCompleted = useMainStore(getOnboardingCompleted);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  if (!onboardingCompleted) {
    return <Navigate to="/onboarding" replace={true} />;
  } else if (isAddNewPractice && incompletePractices && incompletePractices.length > 0) {
    return <Navigate to="/new-practice/practices-names" replace={true} />;
  }

  return (
    <ScreenContainer>
      <Header toggleDrawer={toggleDrawer} drawerOpen={isDrawerOpen || drawerAlwaysOpen} />
      <MainContent $isDrawerOpen={isDrawerOpen}>
        <Outlet />
        <VersionTag />
      </MainContent>
      <Drawer isOpen={isDrawerOpen || drawerAlwaysOpen} toggleDrawer={toggleDrawer} />
    </ScreenContainer>
  );
};

const MainContent = styled.div<{ $isDrawerOpen: boolean }>`
  padding-left: 250px;
  width: 100vw;
  transition: padding-left 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  padding-top: 100px;
  padding-bottom: 200px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    padding-left: 0px;
  }
`;
