import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../../firebaseConfig';
import { PracticeContact } from '../../../types/Practices/PracticeContactType';

export const usePracticeContactConnect = (practiceId?: string) => {
  const [practiceContact, setPracticeContact] = useState<PracticeContact>();
  useEffect(() => {
    if (!practiceId) return;
    const sub = onSnapshot(doc(db, 'practices/' + practiceId + '/documents/practiceContact'), (practiceSnapshot) => {
      const practiceData = practiceSnapshot.data() as PracticeContact;
      console.log('CHANGE', practiceData);
      setPracticeContact(practiceData);
    });
    return sub;
  }, [practiceId]);

  return {
    loadingPracticeContact: practiceContact === undefined,
    practiceContact,
  };
};
