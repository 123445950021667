import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { Body } from '../../../styles/textStyles';

export type ProgressStep = {
  title: string;
  depth: number;
  active: boolean | undefined;
  completed: boolean | undefined;
  navigation?: () => void;
  hidden?: boolean;
};

type Props = {
  step: ProgressStep;
};

export const OnboardingProgressButton = ({ step }: Props) => {
  const completed = step.active || step.completed;
  const active = step.active;

  return (
    <Container>
      <Indicator $completed={completed} $depth={step.depth} />
      <InnerButton $active={active} $completed={completed}>
        <StyledBody>{step.title}</StyledBody>
      </InnerButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  cursor: default;
`;

const Indicator = styled.div<{ $completed?: boolean; $depth: number }>`
  width: 4px;
  display: flex;
  background-color: ${(props) => (props.$completed ? colors.Primary : colors.Light)};
  margin-right: ${(props) => 50 + props.$depth * 30}px;
`;

const InnerButton = styled.div<{ $completed?: boolean; $active?: boolean }>`
  flex: 1;
  display: flex;
  background-color: ${(props) => (props.$active ? colors.Light : 'transparent')};
  padding: 8px 20px 8px 20px;
  border-radius: 10px;
  opacity: ${(props) => (!props.$completed && !props.$active ? 0.6 : 1)};
`;

const StyledBody = styled(Body)`
  opacity: 0.6;
`;
