import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonsAccordionComponent } from '../../../core/components/ButtonsAccordionComponent';
import { ConfirmationWaiting } from '../../../core/components/loading/ConfirmationWaiting';
import { useMainStore } from '../../../store/mainStore';
import { getUserMail, getUserMailToChangeTo } from '../../../store/userSlice';
import { colors } from '../../../styles/colors';
import { H3 } from '../../../styles/textStyles';

export const ResetCredentialsComponent = () => {
  const mailToChangeTo = useMainStore(getUserMailToChangeTo);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentEmail = useMainStore(getUserMail);
  const handleOpenEmailChangeModel = () => {
    navigate('change-email');
  };

  const handleOpenPasswordChangeModel = () => {
    navigate('change-password');
  };

  return (
    <Container>
      <StyledH3>{t('RESET-CREDENTIALS-COMPONENT-TITLE')}</StyledH3>
      {mailToChangeTo && (
        <ConfirmationWaiting
          loading={mailToChangeTo !== undefined}
          text={t('CHANGE-EMAIL-STATUS', { newMail: mailToChangeTo })}
        />
      )}
      <ButtonsAccordionComponent
        title={currentEmail ?? ''}
        items={[
          {
            title: t('CHANGE-EMAIL-BUTTON'),
            onClick: handleOpenEmailChangeModel,
          },
        ]}
      />
      <ButtonsAccordionComponent
        title={'********'}
        items={[
          {
            title: t('CHANGE-PASSWORD-BUTTON'),
            onClick: handleOpenPasswordChangeModel,
          },
        ]}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledH3 = styled(H3)`
  color: ${colors.Medium};
  margin-bottom: 20px;
`;
