import { useEffect, useRef } from 'react';

export const useHandleScroll = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);
  const scrollableAncestorRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const getScrollableAncestor = (element) => {
      while (element && element !== document.body) {
        const className = element.className;
        if (className.includes('onBoardingScreenContainer-scroll-ancestor')) {
          return element;
        }
        element = element.parentElement;
      }
      return window; // Fallback to window if no scrollable ancestor is found
    };
    const updateChildPosition = () => {
      const container = containerRef.current;
      const child = childRef.current;

      if (container && child) {
        const rect = container.getBoundingClientRect();
        child.style.position = 'fixed';
        child.style.top = `${Math.max(100, rect.top)}px`;
        child.style.right = `${window.innerWidth - rect.right}px`;
      }
    };

    const handleScroll = () => {
      updateChildPosition();
    };

    const handleResize = () => {
      updateChildPosition();
    };

    // Get the scrollable ancestor
    scrollableAncestorRef.current = getScrollableAncestor(containerRef.current);

    // Add scroll event listener to the scrollable ancestor
    const scrollableAncestor = scrollableAncestorRef.current;
    if (scrollableAncestor) {
      scrollableAncestor.addEventListener('scroll', handleScroll);
    }

    window.addEventListener('resize', handleResize);

    updateChildPosition();

    // Cleanup scroll event listener on component unmount
    return () => {
      if (scrollableAncestor) {
        scrollableAncestor.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return { containerRef, childRef };
};
