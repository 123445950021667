import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { ButtonGroup } from '../../../core/components/buttons/ButtonGroup';
import { InfoText } from '../../../core/components/text/InfoText';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { checkIfValidEmail } from '../utils/checkIfValidMail';
import { sendPasswordResetMail } from '../utils/sendPasswordResetMail';

export const PasswordResetScreen = () => {
  const initialMail = useLocation()?.state?.email;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState(initialMail ?? '');
  const [error, setError] = useState('');
  const [sendEmail, setSendEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const currentStep = email !== '' ? 1 : 0;

  const sendPasswordResetInstructions = async () => {
    if (!checkIfValidEmail(email)) {
      setError(t('ONBOARDING-ERROR-INVALID-MAIL'));
      return;
    }
    setLoading(true);
    setError('');
    try {
      const result: any = await sendPasswordResetMail(email);
      if (result?.status === 'error') {
        console.log(result);
        setError(t('ONBOARDING-ERROR-PASSWORD-RESET-MAIL'));
        return;
      }
      setSendEmail(email);
    } catch (e) {
      console.log(e);
      setError(t('ONBOARDING-ERROR-PASSWORD-RESET-GENERAL'));
    } finally {
      setEmailSent(true);
      setLoading(false);
    }
  };

  const changeMail = (newMail: string) => {
    setError('');
    setEmail(newMail);
  };

  const navigateToLogin = () => {
    navigate('../login');
  };

  return (
    <OnboardingScreenContainer hideProgress handleBack={navigateToLogin}>
      <InfoText
        headline={t('ONBOARDING-PASSWORD-FORGOTTEN-TITLE')}
        text={
          !emailSent
            ? [t('ONBOARDING-PASSWORD-FORGOTTEN-SUBTITLE'), t('ONBOARDING-PASSWORD-FORGOTTEN-INSTRUCTION')]
            : [
                t('ONBOARDING-NEW-PASSWORD-USED-EMAIL', { emailAddress: sendEmail }),
                t('ONBOARDING-NEW-PASSWORD-INSTRUCTION'),
                t('ONBOARDING-NEW-PASSWORD-EMAIL-NOT-RECEIVED-TIP'),
              ]
        }
      />
      <SingleTextInput
        value={email}
        onChange={changeMail}
        placeholder={t('ONBOARDING-NEW-PASSWORD-EMAIL-FIELD')}
        error={error}
      />
      <ButtonContainer>
        <ButtonGroup
          textButton={
            emailSent
              ? {
                  text: t('ONBOARDING-BUTTON-SEND-AGAIN'),
                  onClick: sendPasswordResetInstructions,
                  loading: loading,
                }
              : undefined
          }
          progressButton={{
            loading,
            text: emailSent ? t('ONBOARDING-BUTTON-BACK-TO-LOGIN') : t('ONBOARDING-BUTTON-SEND-EMAIL'),
            onClick: emailSent ? navigateToLogin : sendPasswordResetInstructions,
            currentStep: emailSent ? 1 : currentStep,
            numberOfSteps: 1,
          }}
        />
      </ButtonContainer>
    </OnboardingScreenContainer>
  );
};

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
