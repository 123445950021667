import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonGroup } from '../../../core/components/buttons/ButtonGroup.tsx';
import { ConfirmationWaiting } from '../../../core/components/loading/ConfirmationWaiting.tsx';
import { InfoText } from '../../../core/components/text/InfoText.tsx';
import { handleLogout } from '../../../core/utils/handleLogout.ts';
import { useMainStore } from '../../../store/mainStore.ts';
import { getEmailVerified, getUserMail } from '../../../store/userSlice.ts';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { sendMailVerification } from '../utils/sendMailVerification.ts';
import { useHandleOnSubmitKeyPress } from '../utils/useHandleOnSubmitKeyPress.tsx';

export const OnboardingVerificationURL = 'verification';

export const OnboardingVerificationScreen = () => {
  const currentMail = useMainStore(getUserMail);
  const currentEmailVerified = useMainStore(getEmailVerified);
  const [emailVerified, setEmailVerified] = useState(currentEmailVerified);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const resend = async () => {
    setLoading(true);
    try {
      if (!currentMail) return;
      await sendMailVerification();
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setLoading(false), 500);
    }
  };

  useEffect(() => {
    const loop = setInterval(async () => {
      const auth = getAuth();
      await auth.currentUser?.reload();
      if (auth.currentUser?.emailVerified) {
        setEmailVerified(true);
        clearInterval(loop);
      }
    }, 500);
    return () => clearInterval(loop);
  }, []);

  const handleNext = () => {
    navigate('../basic-info');
  };

  useHandleOnSubmitKeyPress(handleNext);

  return (
    <OnboardingScreenContainer handleBack={handleLogout}>
      <InfoText
        headline={!emailVerified ? t('ONBOARDING-EMAIL-VERIFICATION-TITLE') : t('ONBOARDING-EMAIL-VERIFIED-TITLE')}
        text={
          !emailVerified
            ? [
                t('ONBOARDING-EMAIL-VERIFICATION-SUBTITLE-EMAIL-SENT', { emailAddress: currentMail }),
                t('ONBOARDING-EMAIL-VERIFICATION-SUBTITLE-EMAIL-NOT-RECEIVED-TIP'),
              ]
            : [t('ONBOARDING-EMAIL-VERIFIED-SUBTITLE')]
        }
      />
      <ConfirmationWaiting
        loading={!emailVerified}
        text={
          !emailVerified
            ? t('ONBOARDING-EMAIL-VERIFICATION-WAITING-FOR-EMAIL-CONFIRMATION')
            : t('ONBOARDING-EMAIL-VERIFIED-CONFIRMATION')
        }
      />
      <ButtonGroup
        textButton={
          !emailVerified
            ? {
                text: t('ONBOARDING-BUTTON-SEND-AGAIN'),
                onClick: resend,
                disabled: !currentMail || emailVerified,
                loading,
              }
            : undefined
        }
        progressButton={{
          disabled: !emailVerified,
          text: t('ONBOARDING-BUTTON-NEXT'),
          onClick: handleNext,
          numberOfSteps: 1,
          currentStep: emailVerified ? 1 : 0,
        }}
      />
    </OnboardingScreenContainer>
  );
};
