import { getFunctions, httpsCallable } from 'firebase/functions';
import { getParameterByName } from '../../onboarding/utils/firebaselVerification';

export const verifyAppMail = async () => {
  try {
    const token = getParameterByName('token');
    const functions = getFunctions();
    console.log('VERIFY');
    const call = httpsCallable(functions, 'verifyPatientEmail');
    const callableReturnMessage = (await call({
      token,
    })) as any;
    console.log('VERIFY', callableReturnMessage);
    if (callableReturnMessage?.data?.status === 'success') {
      return 'SUCCESS';
    }
    return 'INVALID_URL';
  } catch (error) {
    console.log('Error:', error);
    return 'INVALID_URL';
  }
};
