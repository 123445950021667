import { useEffect } from 'react';
import styled from 'styled-components';
import { useHandleInformationForm } from '../utils/useHandleInformationForm';
import { BillingForm } from './BillingForm';

type Props = {
  setCurrentStep: (step: number) => void;
  focusOnFirstInput?: boolean;
  fields: {
    practiceName: string;
    street: string;
    houseNumber: string;
    cityCode: string;
    city: string;
    taxId: string;
  };
  setFields: (fields: any) => void;
};

export const InvoiceAddressInformation = ({ fields, setFields, setCurrentStep, focusOnFirstInput }: Props) => {
  const { handleInput } = useHandleInformationForm(fields, setFields, setCurrentStep, ['taxId', 'complement']);

  useEffect(() => {
    if (focusOnFirstInput) {
      document.getElementById('firstInput')?.focus();
    }
  }, []);

  return (
    <Container>
      <BillingForm fields={fields} handleInput={handleInput} setFields={setFields} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;
