// MainLayout.js
import { Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { ScreenContainer } from '../core/components/container/ScreenContainer';
import { getOnboardingCompleted } from '../store/chainSlice';
import { useMainStore } from '../store/mainStore';

import { VersionTag } from '../core/components/VersionTag';
import { Header } from './components/Header';
import { useScrollToTop } from './utils/useScrollToTop';

export const WithoutDrawerLayout = () => {
  useScrollToTop();
  const onboardingCompleted = useMainStore(getOnboardingCompleted);

  if (!onboardingCompleted) {
    return <Navigate to="/onboarding" replace={true} />;
  }

  return (
    <ScreenContainer>
      <Header />
      <MainContent className="onBoardingScreenContainer-scroll-ancestor">
        <Outlet />
      </MainContent>
      <VersionTag />
    </ScreenContainer>
  );
};

const MainContent = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 100px;
`;
