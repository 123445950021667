import React, { ForwardedRef } from 'react';
import styled from 'styled-components';
import { getIsAddNewPractice } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { generateProgressDataNewPractice } from '../utils/generateProgressDataNewPractice';
import { generateOnboardingProgressData } from '../utils/generateProgressDataOnboarding';
import { OnboardingProgressButton } from './OnboardingProgressButton';

type Props = {
  className?: string;
  activeStep?: number;
};

export const OnboardingProgress = React.forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const isAddNewPractice = useMainStore(getIsAddNewPractice);
  const mainProgress = isAddNewPractice ? generateProgressDataNewPractice() : generateOnboardingProgressData();

  return (
    <Container className={props.className} ref={ref}>
      {mainProgress.map((step, index) => {
        if (step.hidden) return null;
        return <OnboardingProgressButton key={'ProgressStep' + index} step={step} />;
      })}
    </Container>
  );
});

const Container = styled.div`
  width: 285px;
`;
