import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useMainStore } from '../../../store/mainStore';
import { getUserChainId, getUserId, getUserMail } from '../../../store/userSlice';

export const useUpdateSentry = () => {
  const userId = useMainStore(getUserId);
  const userEmail = useMainStore(getUserMail);
  const userChainId = useMainStore(getUserChainId);

  useEffect(() => {
    if (userId) {
      Sentry.setUser({
        id: userId,
        email: userEmail,
        chainId: userChainId,
      });
    }
  }, [userId, userEmail, userChainId]);
};
