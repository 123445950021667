import { bankDataByIBAN } from 'bankdata-germany';
import { isValidIBAN } from 'ibantools-germany';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LabeledSwitchButton } from '../../../core/components/buttons/LabeledSwitchButton';
import { TagSelection } from '../../../core/components/tags/TagSelection';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { colors } from '../../../styles/colors';
import { H3 } from '../../../styles/textStyles';
import { PracticePayout } from '../../../types/Practices/PracticePayoutType';
import { useHandleInformationForm } from '../utils/useHandleInformationForm';
import { useHandleTransferPracticeData } from '../utils/useHandleTransferPracticeData';

type FormFields = {
  owner: string;
  iBan: string;
  bankName: string;
  bic: string;
  copyId: string;
};

type Props = {
  setCurrentStep?: (step: number) => void;
  practiceIndex: number;
  fields: FormFields;
  setFields: (value: FormFields) => void;
  focusOnFirstInput?: boolean;
  loadingData?: boolean;
};

export const BankInformationForm = ({
  focusOnFirstInput,
  setCurrentStep,
  practiceIndex,
  fields,
  setFields,
  loadingData,
}: Props) => {
  const { t } = useTranslation();
  const [iBanError, setIBanError] = useState('');

  const handleTransfer = (data: PracticePayout) => {
    setFields({
      owner: data.payoutAccount?.accountHolder ?? '',
      iBan: data.payoutAccount?.iban ?? '',
      bankName: data.payoutAccount?.bankName ?? '',
      bic: data.payoutAccount?.bic ?? '',
      copyId: data.practiceId ?? '',
    });
  };

  const clearData = () => {
    setFields({
      owner: '',
      iBan: '',
      bankName: '',
      bic: '',
      copyId: '',
    });
  };

  const {
    showToggle,
    toggleActive,
    handleToggle,
    practicesToTransfer,
    setToggleInactive,
    setSelectedTransferPractice,
  } = useHandleTransferPracticeData(practiceIndex, handleTransfer, clearData, fields.copyId);

  const setFieldsHelper = (newFields: any) => {
    setToggleInactive();
    setFields({ ...newFields, copyId: '' });
  };

  const { handleInput, changeMultipleFields } = useHandleInformationForm(fields, setFieldsHelper, setCurrentStep, [
    'bic',
    'bankName',
  ]);

  const { owner, iBan, bankName } = fields;
  const checkForBank = () => {
    if (!isValidIBAN(iBan)) {
      setIBanError('Ungültige IBAN'); // TODO: i18n
    }
    const bankData = bankDataByIBAN(iBan);
    console.log('bankData', bankData);
    if (bankData?.bankName) {
      changeMultipleFields({ bankName: bankData?.bankName, bic: bankData?.bic });
    }
  };

  const changeIBan = (newIBan: string) => {
    setIBanError('');
    let newIBanWithoutSpaces = newIBan.replace(/\s/g, '');
    newIBanWithoutSpaces = newIBanWithoutSpaces.toUpperCase().trim();
    if (newIBanWithoutSpaces.length > 34) {
      setIBanError('Ungültige IBAN');
      newIBanWithoutSpaces = newIBanWithoutSpaces.slice(0, 34);
    }

    handleInput('iBan', newIBanWithoutSpaces);
  };

  useEffect(() => {
    if (focusOnFirstInput) {
      document.getElementById('firstInput')?.focus();
    }
  }, []);

  return (
    <Container>
      <StyledH3>{t('ONBOARDING-BANK-INFORMATION-FORM-TITLE')}</StyledH3>
      {showToggle && (
        <LabeledSwitchButton
          label={t('ONBOARDING-BANK-INFORMATION-FORM-TRANSFER-DATA')}
          isChecked={toggleActive}
          setIsChecked={handleToggle}
        />
      )}
      {toggleActive && (
        <TagSelection
          selected={[fields.copyId]}
          tags={practicesToTransfer?.map((practice) => ({ text: practice.name, value: practice.id })) ?? []}
          setSelected={(value) => {
            setSelectedTransferPractice(value?.[0] as string);
          }}
          type={'singleSelect'}
        />
      )}
      <FormContainer>
        <SingleTextInput
          loadingData={loadingData}
          id="firstInput"
          type="name"
          value={owner}
          onChange={(value) => handleInput('owner', value)}
          placeholder={t('ONBOARDING-BANK-INFORMATION-FORM-ACCOUNT-HOLDER')}
        />
        <SingleTextInput
          loadingData={loadingData}
          value={iBan.match(/.{1,4}/g)?.join(' ') ?? ''}
          onChange={changeIBan}
          placeholder={t('ONBOARDING-BANK-INFORMATION-FORM-IBAN')}
          onBlur={checkForBank}
          error={iBanError}
        />
        <SingleTextInput
          loadingData={loadingData}
          disabled
          value={bankName}
          onChange={(value) => handleInput('bankName', value)}
          placeholder={t('ONBOARDING-BANK-INFORMATION-FORM-BANK-NAME')}
        />
      </FormContainer>
    </Container>
  );
};

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 20px;
  width: 100%;
`;

const StyledH3 = styled(H3)`
  color: ${colors.Medium};
`;
