import { PracticePayoutInfo } from '../../../types/Administration/PracticePayoutType';

const getLocalizedMonthString = (date: Date): string => {
  return date.toLocaleString('de-DE', { month: 'long' });
};

const getQuartal = (date: Date): number => {
  return Math.floor(date.getMonth() / 3) + 1;
};

const getFileName = (date: Date): string => {
  const year = date.getFullYear();
  const quartal = getQuartal(date);
  const monthName = getLocalizedMonthString(date);
  return `${year}_Q${quartal}_${monthName}_Physiofit-Gutschrift.pdf`;
};
export const downloadPayoutPDF = async (data: PracticePayoutInfo) => {
  try {
    if (!data.downloadUrl) {
      throw new Error('No download URL found');
    }
    // Fetch the file content
    const response = await fetch(data.downloadUrl);
    const blob = await response.blob();

    // Create a Blob URL
    const blobUrl = window.URL.createObjectURL(blob);

    const filename = getFileName(new Date(data.date));

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', filename);

    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error downloading the PDF:', error);
    throw error;
  }
};

export const openPDFInNewTab = async (payout: PracticePayoutInfo) => {
  try {
    if (!payout.downloadUrl) {
      throw new Error('No download URL found');
    }
    const response = await fetch(payout.downloadUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const filename = getFileName(new Date(payout.date));

    const newWindow = window.open(blobUrl, '_blank');
    if (newWindow) {
      newWindow.document.title = filename;
    }
  } catch (error) {
    console.error('Error opening PDF:', error);
    throw error;
  }
};
