import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { GenericSvg } from '../../assets/svgs/GenericSVG';
import { colors } from '../../styles/colors';
import { H4 } from '../../styles/textStyles';
import { CustomButton } from './buttons/CustomButton';

type Props = {
  title: string;
  children: React.ReactNode;
};

export const AccordionComponent: React.FC<Props> = ({ title, children }) => {
  const [active, setActive] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (contentRef.current) {
      setHeight(active ? contentRef.current.scrollHeight : 0);
    }
  }, [active]);

  const handleToggle = () => {
    setActive(!active);
  };

  return (
    <AccordionItem>
      <AccordionButton onClick={handleToggle}>
        <StyledH4>{title}</StyledH4>
        <RotatingIconButton name="ArrowDown" $isRotated={active} size={32} primaryColor={colors.Medium} />
      </AccordionButton>
      <ContentContainer $active={active} style={{ height }} ref={contentRef}>
        {children}
      </ContentContainer>
    </AccordionItem>
  );
};

const AccordionItem = styled.div`
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
`;

const AccordionButton = styled(CustomButton)`
  width: 100%;
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RotatingIconButton = styled(GenericSvg)<{ $isRotated: boolean }>`
  transform: rotate(${(props) => (props.$isRotated ? '180deg' : '0deg')});
  transition: transform 0.3s ease;
`;

const ContentContainer = styled.div<{ $active: boolean }>`
  overflow: hidden;
  transition: height 0.3s ease-in-out;
`;

const StyledH4 = styled(H4)`
  color: ${colors.Medium};
`;
