const firebaseKeys = {
  development: {
    apiKey: 'AIzaSyDg0snqc1N0ZSM-xua-14QRQ5nUuF7wkv4',
    authDomain: 'io-physiofit-development.firebaseapp.com',
    projectId: 'io-physiofit-development',
    storageBucket: 'io-physiofit-development.appspot.com',
    messagingSenderId: '374543744741',
    appId: '1:374543744741:web:55c5b91ae40b7ad77a9e0c',
    measurementId: 'G-677VCFQ3X8',
  },
  staging: {
    apiKey: 'AIzaSyA1B1sAq5BXMnaEx8n-A8ygadQN75nil1Q',
    authDomain: 'io-physiofit-staging.firebaseapp.com',
    projectId: 'io-physiofit-staging',
    storageBucket: 'io-physiofit-staging.appspot.com',
    messagingSenderId: '1007946300422',
    appId: '1:1007946300422:web:edbfdb9da752dd84cad787',
    measurementId: 'G-0V1MTY02TM',
  },
  production: {
    apiKey: 'AIzaSyDDvHghdYNc_AuO0YPKTZ47cPHX9LXJDGg',
    authDomain: 'io-physiofit-production.firebaseapp.com',
    projectId: 'io-physiofit-production',
    storageBucket: 'io-physiofit-production.appspot.com',
    messagingSenderId: '397410004381',
    appId: '1:397410004381:web:09858111e03d4579955374',
    measurementId: 'G-BG894TG69K',
  },
};

export default firebaseKeys;
