import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonGroup } from '../../../core/components/buttons/ButtonGroup';
import { TextButton } from '../../../core/components/buttons/TextButton';
import { ConfirmationWaiting } from '../../../core/components/loading/ConfirmationWaiting';
import { InfoText } from '../../../core/components/text/InfoText';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { colors } from '../../../styles/colors';
import { Body60 } from '../../../styles/textStyles';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';

export const LoginScreen = () => {
  const changedPassword = useLocation()?.state?.changedPassword;
  const hasBack = useLocation()?.state?.hasBack;
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const currentStep = email !== '' && password !== '' ? 2 : email !== '' || password !== '' ? 1 : 0;

  const openLink = () => {
    window.open('https://www.physiofit.app/partner-werden', '_self');
  };

  const forgotPassword = () => {
    navigate('../password-reset', { state: { email } });
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log(userCredential?.user?.uid);
    } catch (error: any) {
      if (error.message.toLowerCase().includes('network error')) {
        setErrorMessage('Keine Internetverbindung');
      } else {
        setErrorMessage('Email oder Passwort falsch');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (newEmail: string) => {
    setErrorMessage('');
    setEmail(newEmail);
  };

  const handlePasswordChange = (newPassword: string) => {
    setErrorMessage('');
    setPassword(newPassword);
  };
  const handleBack = () => {
    navigate('../');
  };

  return (
    <OnboardingScreenContainer hideProgress handleBack={handleBack} hideBackButton={!hasBack}>
      <InfoText headline={t('ONBOARDING-LOGIN-TITLE')} text={[t('ONBOARDING-LOGIN-SUBTITLE')]} />
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleLogin();
        }}
      >
        <SingleTextInput
          value={email}
          onChange={handleEmailChange}
          placeholder={t('ONBOARDING-LOGIN-EMAIL-FIELD')}
          type="email"
          autoComplete="username"
        />
        <SingleTextInput
          value={password}
          onChange={handlePasswordChange}
          placeholder={t('ONBOARDING-LOGIN-PASSWORD')}
          error={errorMessage}
          type="password"
          autoComplete="current-password"
        />
      </FormContainer>
      {changedPassword && (
        <ConfirmationWaiting loading={false} text={t('ONBOARDING-LOGIN-PASSWORD-CHANGING-SUCCESS')} />
      )}
      <ButtonGroup
        progressButton={{
          text: t('ONBOARDING-BUTTON-LOGIN'),
          onClick: handleLogin,
          currentStep: currentStep,
          numberOfSteps: 2,
          loading,
        }}
        textButton={{
          text: t('ONBOARDING-BUTTON-FORGOT-PASSWORD'),
          onClick: forgotPassword,
        }}
      />
      <Divider />
      <MoreInfoContainer>
        <AppointmentTextContainer>
          <Body60>{t('ONBOARDING-LOGIN-BOOK-APPOINTMENT-TEXT')}</Body60>
        </AppointmentTextContainer>
        <AppointmentButtonContainer>
          <TextButton text={t('ONBOARDING-BUTTON-BOOK-APPOINTMENT')} onClick={openLink} />
        </AppointmentButtonContainer>
      </MoreInfoContainer>
    </OnboardingScreenContainer>
  );
};

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MoreInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const AppointmentTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;
const AppointmentButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 40%;
`;

const Divider = styled.div`
  width: 100%;
  padding: 0 10px;
  border-top: 2px solid ${colors.Light};
`;
