import styled from 'styled-components';
import { Body } from '../../../styles/textStyles';
import { Switch } from './SwitchButton';

type Props = {
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
  label: string;
};

export const LabeledSwitchButton = (props: Props) => {
  return (
    <HorizontalContainer>
      <Body>{props.label}</Body>
      <Switch isChecked={props.isChecked} setIsChecked={props.setIsChecked} />
    </HorizontalContainer>
  );
};

const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;
