import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { hexWithOpacity } from '../../utils/hexWithOpacity';

type SwitchProps = {
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
};

export const Switch = ({ isChecked, setIsChecked }: SwitchProps) => {
  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  return (
    <SwitchWrapper>
      <SwitchLabel>
        <SwitchInput type="checkbox" checked={isChecked} onChange={handleToggle} />
        <SwitchSlider />
      </SwitchLabel>
    </SwitchWrapper>
  );
};

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${hexWithOpacity(colors.Medium, 0.5)};
  transition: 0.4s;
  border-radius: 16px;

  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: ${colors.White};
    transition: 0.4s;
    border-radius: 50%;
  }

  ${SwitchInput}:checked + & {
    background-color: ${colors.Primary};
  }

  ${SwitchInput}:checked + &:before {
    transform: translateX(24px);
  }
`;
