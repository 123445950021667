import styled from 'styled-components';
import { GenericSvg, SVGName } from '../../../assets/svgs/GenericSVG';
import { colors } from '../../../styles/colors';
import { H2, H4 } from '../../../styles/textStyles';

type Props = {
  steps: {
    text: string;
    iconName?: SVGName;
  }[];
};

export const TutorialSteps = ({ steps }: Props) => {
  return (
    <Container>
      {steps.map((step, index) => (
        <Step key={index}>
          <Number>{index + 1 + '.'}</Number>
          <StyledH4>{step.text}</StyledH4>
          {step.iconName && <GenericSvg name={step.iconName} size={32} primaryColor={colors.Primary} />}
        </Step>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0px 10px;
`;

const Number = styled(H2)`
  color: ${colors.Primary};
`;

const StyledH4 = styled(H4)`
  text-align: center;
  width: 100%;
  text-align: left;
  color: ${colors.Medium};
`;
