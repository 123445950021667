import { Outlet } from 'react-router-dom';
import { EventIndicator } from '../core/components/EventIndicator';

export const MainLayout = () => {
  return (
    <>
      <Outlet />
      <EventIndicator />
    </>
  );
};
