import { Outlet, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { InfoText } from '../../core/components/text/InfoText.tsx';
import { getChainPracticeName } from '../../store/chainSlice.ts';
import { useMainStore } from '../../store/mainStore.ts';
import { MainScreenContainer } from '../overview/components/MainScreenComponent.tsx';
import { PracticeSettingsContactForm } from './components/PracticeSettingsContactForm.tsx';
import { PracticeSettingsTherapists } from './components/PracticeSettingsTherapists.tsx';

export const PracticeSettingsTeamScreen = () => {
  const { practiceId } = useParams();
  const practiceName = useMainStore((state) => getChainPracticeName(state, practiceId));

  return (
    <>
      <MainScreenContainer>
        <Container>
          <InfoText headline={'Deine Team am Standort ' + practiceName} />
          <PracticeSettingsContactForm />
          <PracticeSettingsTherapists />
        </Container>
      </MainScreenContainer>
      <Outlet />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 200px;
`;
