import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { InfoText } from '../../../core/components/text/InfoText';
import { setChainOnboardingFlag } from '../../../core/utils/editFirestore/editChain';
import { editOwner } from '../../../core/utils/editFirestore/editOwner';
import { getInvitationFirstName, getInvitationLastName } from '../../../store/invitationSlice';
import { useMainStore } from '../../../store/mainStore';
import { getUser } from '../../../store/userSlice';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { PersonalInfoForm } from '../components/PersonalInfoForm';
import { useHandleOnSubmitKeyPress } from '../utils/useHandleOnSubmitKeyPress';

export const OnboardingUserBasicInfoURL = 'basic-info';

export const OnboardingUserBasicInfoScreen = () => {
  const { t } = useTranslation();
  const invitationFirstName = useMainStore(getInvitationFirstName);
  const invitationLastName = useMainStore(getInvitationLastName);
  const userData = useMainStore(getUser);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    firstName: userData?.privateInfo?.firstName ?? invitationFirstName ?? '',
    lastName: userData?.privateInfo?.lastName ?? invitationLastName ?? '',
    phoneNumber: userData?.privateInfo?.phoneNumber ?? '',
    gender: userData?.privateInfo?.gender ?? '',
  });
  const navigate = useNavigate();

  const currentSteps = Object.values(fields).filter((value) => value !== '').length;

  const handleNext = async () => {
    if (currentSteps < 4) return;
    setLoading(true);
    try {
      const changeObj = {
        'privateInfo.gender': fields.gender,
        'privateInfo.firstName': fields.firstName,
        'privateInfo.lastName': fields.lastName,
        'privateInfo.phoneNumber': fields.phoneNumber,
      };
      await setChainOnboardingFlag(`basicInfo`, 'basicInfo');

      await editOwner(changeObj);
      navigate('../referral');
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useHandleOnSubmitKeyPress(handleNext);

  return (
    <OnboardingScreenContainer
      hideBackButton
      nextButton={{
        loading,
        onClick: handleNext,
        numberOfSteps: 4,
        currentStep: currentSteps,
      }}
    >
      <InfoText headline={t('ONBOARDING-BASIC-INFO-TITLE')} />
      <PersonalInfoForm fields={fields} setFields={setFields} />
    </OnboardingScreenContainer>
  );
};
