import { doc, updateDoc } from 'firebase/firestore';
import { isValidIBAN } from 'ibantools-germany';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ProgressButton } from '../../../core/components/buttons/ProgressButton';
import { ContentContainer } from '../../../core/components/container/ContentContainer';
import { addValueIfExists } from '../../../core/utils/addValueIfExists';
import { usePracticePayoutConnect } from '../../../core/utils/connectFirestore/usePracticePayoutConnect';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { BankInformationForm } from '../../onboarding/components/BankInformationForm';
import { PayoutAddressInformation } from '../../onboarding/components/PayoutAddressInformation';

export const PracticeSettingsPayoutAddressForm = () => {
  const { practiceId } = useParams();
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { loadingPracticePayout, practicePayout } = usePracticePayoutConnect(practiceId);

  const payoutAccount = practicePayout?.payoutAccount;
  const payoutAddress = practicePayout?.payoutAddress;
  const [bankFields, setBankFields] = useState({
    owner: payoutAccount?.accountHolder ?? '',
    iBan: payoutAccount?.iban ?? '',
    bankName: payoutAccount?.bankName ?? '',
    bic: payoutAccount?.bic ?? '',
    copyId: '',
  });
  const [addressFields, setAddressFields] = useState({
    practiceName: payoutAddress?.payoutRecipient ?? '',
    street: payoutAddress?.street ?? '',
    cityCode: payoutAddress?.cityCode ?? '',
    city: payoutAddress?.city ?? '',
    taxId: practicePayout?.taxId ?? '',
    houseNumber: payoutAddress?.houseNumber ?? '',
    copyId: '',
  });
  useEffect(() => {
    setBankFields({
      owner: payoutAccount?.accountHolder ?? '',
      iBan: payoutAccount?.iban ?? '',
      bankName: payoutAccount?.bankName ?? '',
      bic: payoutAccount?.bic ?? '',
      copyId: '',
    });
    setAddressFields({
      practiceName: payoutAddress?.payoutRecipient ?? '',
      street: payoutAddress?.street ?? '',
      cityCode: payoutAddress?.cityCode ?? '',
      city: payoutAddress?.city ?? '',
      taxId: practicePayout?.taxId ?? '',
      houseNumber: payoutAddress?.houseNumber ?? '',
      copyId: '',
    });
  }, [payoutAccount, payoutAddress, practicePayout]);

  const [loading, setLoading] = useState(false);

  const safeData = async () => {
    try {
      const data = {
        'payoutAddress.payoutRecipient': addressFields.practiceName,
        'payoutAddress.street': addressFields.street,
        'payoutAddress.houseNumber': addressFields.houseNumber,
        'payoutAddress.cityCode': addressFields.cityCode,
        'payoutAddress.city': addressFields.city,
        'payoutAccount.accountHolder': bankFields.owner,
        'payoutAccount.iban': bankFields.iBan,
        ...addValueIfExists('payoutAccount.bankName', bankFields.bankName),
        ...addValueIfExists('payoutAccount.bic', bankFields.bic),
        ...addValueIfExists('taxId', addressFields.taxId),
        practiceName: addressFields.practiceName,
        practiceId: practiceId,
        onboardingCopyIBanId: bankFields.copyId,
        onboardingCopyAddressId: addressFields.copyId,
      };
      await updateDoc(doc(db, 'practices/' + practiceId + '/documents/payoutData'), data);
      setEventIndicator('success', 'Daten gespeichert');
    } catch (error) {
      setEventIndicator('error', 'Fehler beim Speichern der Daten');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const missingData = [...Object.values(bankFields), ...Object.values(addressFields)].some((value) => value === '');
  const noChange =
    bankFields.owner === payoutAccount?.accountHolder &&
    bankFields.iBan === payoutAccount?.iban &&
    bankFields.bankName === payoutAccount?.bankName &&
    bankFields.bic === payoutAccount?.bic &&
    addressFields.practiceName === payoutAddress?.payoutRecipient &&
    addressFields.street === payoutAddress?.street &&
    addressFields.cityCode === payoutAddress?.cityCode &&
    addressFields.city === payoutAddress?.city &&
    addressFields.taxId === practicePayout?.taxId &&
    addressFields.houseNumber === payoutAddress?.houseNumber;

  const buttonDisabled = missingData || noChange || !isValidIBAN(bankFields.iBan);

  return (
    <ContentContainer>
      <BankInformationForm
        practiceIndex={0}
        fields={bankFields}
        setFields={setBankFields}
        loadingData={loadingPracticePayout}
      />
      <PayoutAddressInformation
        practiceIndex={0}
        fields={addressFields}
        setFields={setAddressFields}
        loadingData={loadingPracticePayout}
      />
      <ButtonContainer>
        <ProgressButton onClick={safeData} loading={loading} text={'Speichern'} disabled={buttonDisabled} />
      </ButtonContainer>
    </ContentContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
