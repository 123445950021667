import { getFunctions, httpsCallable } from 'firebase/functions';

export const sendChangeEmailLink = async (newEmail: string) => {
  try {
    const functions = getFunctions();
    const call = httpsCallable(functions, 'changeDashboardEmailAddress');
    const callableReturnMessage = (await call({
      newEmail,
    })) as { data: { status: string; message: string } };
    return callableReturnMessage;
  } catch (e: any) {
    throw new Error('Error sending change email link ' + e.message);
  }
};
