import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TagSelection } from '../../../core/components/tags/TagSelection';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { enforceNumeric } from '../../../core/utils/phoneNumberRegex';

enum Genders {
  female = 'female',
  male = 'male',
  other = 'other',
}

type Props = {
  fields: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    gender: string;
  };
  setFields: (fields: any) => void;
  loadingData?: boolean;
};

const tags = [
  { text: 'ONBOARDING-BASIC-INFO-GENDER-TAG-FEMALE', value: Genders.female },
  { text: 'ONBOARDING-BASIC-INFO-GENDER-TAG-MALE', value: Genders.male },
  { text: 'ONBOARDING-BASIC-INFO-GENDER-TAG-NEUTRAL', value: Genders.other },
];

export const PersonalInfoForm = ({ fields, setFields, loadingData }: Props) => {
  const { t } = useTranslation();

  const handleFillField = (field: keyof Props['fields'], value: string) => {
    if (field === 'phoneNumber') {
      value = enforceNumeric(value);
    }
    setFields({ ...fields, [field]: value });
  };

  return (
    <>
      <TagSelection
        selected={[fields.gender]}
        tags={tags}
        setSelected={(selected) => handleFillField('gender', selected[0] as string)}
        type="singleSelect"
      />
      <FormContainer>
        <NameContainer>
          <SingleTextInput
            loadingData={loadingData}
            id="firstInput"
            placeholder={t('ONBOARDING-BASIC-INFO-GENDER-FIELD-NAME')}
            value={fields.firstName as string}
            onChange={(value) => {
              handleFillField('firstName', value);
            }}
          />
          <SingleTextInput
            loadingData={loadingData}
            placeholder={t('ONBOARDING-BASIC-INFO-GENDER-FIELD-FAMILY-NAME')}
            value={fields.lastName as string}
            onChange={(value) => {
              handleFillField('lastName', value);
            }}
          />
        </NameContainer>
        <SingleTextInput
          loadingData={loadingData}
          placeholder={t('ONBOARDING-BASIC-INFO-GENDER-FIELD-PHONE')}
          value={fields.phoneNumber as string}
          onChange={(value) => {
            handleFillField('phoneNumber', value);
          }}
        />
      </FormContainer>
    </>
  );
};

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: center;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;
