import { getFunctions, httpsCallable } from 'firebase/functions';
import { getInvitationId } from '../../../store/invitationSlice';
import { useMainStore } from '../../../store/mainStore';

export const createPracticeChainOwner = async (newsletterAccepted?: boolean) => {
  const functions = getFunctions();
  const state = useMainStore.getState();
  const invitationId = getInvitationId(state);
  const call = httpsCallable(functions, 'createPracticeChainOwner');
  const callableReturnMessage = await call({
    newsletterAccepted: newsletterAccepted ?? false,
    invitationId,
  });
  console.log(callableReturnMessage);
};
