import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CustomButton } from '../../../core/components/buttons/CustomButton';
import { hexWithOpacity } from '../../../core/utils/hexWithOpacity';
import { colors } from '../../../styles/colors';
import { Body } from '../../../styles/textStyles';

type Props = {
  path: string;
  title: string;
  closeDrawer: () => void;
};

export const DrawerNavItem = ({ path, title, closeDrawer }: Props) => {
  const navigate = useNavigate();
  const selected = window.location.pathname.includes(path);
  const handleClick = () => {
    closeDrawer();
    navigate(path, { replace: true });
  };
  return (
    <Container $selected={selected} onClick={handleClick}>
      <Body>{title}</Body>
    </Container>
  );
};

const Container = styled(CustomButton)<{ $selected: boolean }>`
  display: flex;
  margin: 5px 0;
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
  justify-content: 'flex-start';
  background-color: ${(props) => (props.$selected ? colors.Light : 'transparent')};
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${(props) => (props.$selected ? colors.Light : hexWithOpacity(colors.Primary, 0.1))};
  }
`;
