import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect } from 'react';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { getUserChainId } from '../../../store/userSlice';
import { Chain } from '../../../types/Practices/ChainType';

export const usePracticeChainConnect = () => {
  const chainId = useMainStore(getUserChainId);
  const setChainData = useMainStore((state) => state.setChainData);
  useEffect(() => {
    if (!chainId) return;
    const subscription = onSnapshot(doc(db, 'practiceChains/' + chainId), (snapshot) => {
      if (snapshot?.data()) {
        setChainData(snapshot.data() as Chain);
      }
    });
    return () => subscription();
  }, [setChainData, chainId]);
};
