import { getFunctions, httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const useCreateBillingPortalSession = () => {
  const practiceId = useParams().practiceId;

  const [stripeSessionUrl, setStripeSessionUrl] = useState<string>();

  useEffect(() => {
    if (!practiceId) return;
    const loadStripe = async () => {
      const functions = getFunctions();
      const call = httpsCallable(functions, 'createStripeBillingPortalSession');
      const callableReturnMessage = (await call({
        practiceId: practiceId,
        returnUrl: window.location.origin + '/practice-settings/' + practiceId + '/team',
      })) as { data: { status: string; sessionUrl: string; message: string } };
      if (callableReturnMessage?.data?.status === 'success') {
        setStripeSessionUrl(callableReturnMessage.data?.sessionUrl);
      }

      console.log(callableReturnMessage);
    };
    loadStripe();
  }, [practiceId]);

  return { loadingStripeSessionUrl: !stripeSessionUrl, stripeSessionUrl };
};
