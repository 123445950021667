import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { InfoText } from '../../core/components/text/InfoText';
import { getChainPracticeName } from '../../store/chainSlice';
import { useMainStore } from '../../store/mainStore';
import { MainScreenContainer } from '../overview/components/MainScreenComponent';
import { PayoutHistory } from './components/PayoutHistory';
import { PracticeSettingsPayoutAddressForm } from './components/PracticeSettingsPayoutForm';

export const PracticeSettingsPayoutScreen = () => {
  const practiceId = useParams().practiceId;
  const practiceName = useMainStore((state) => getChainPracticeName(state, practiceId));

  return (
    <MainScreenContainer>
      <Container>
        <InfoText headline={'Deine Gutschrift für den Standort ' + practiceName} />
        <PracticeSettingsPayoutAddressForm />
        <PayoutHistory />
      </Container>
    </MainScreenContainer>
  );
};

const Container = styled.div`
  padding-bottom: 200px;
`;
