import { doc, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ButtonGroup } from '../../../core/components/buttons/ButtonGroup';
import { ModalContainer } from '../../../core/components/container/ModalContainer';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { H3 } from '../../../styles/textStyles';

export const TherapistSettingsRenameModal = () => {
  const therapist = useLocation()?.state?.therapist;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const [firstName, setFirstName] = useState(therapist.firstName);
  const [lastName, setLastName] = useState(therapist.lastName);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    navigate('..');
  };

  const renameTherapist = async () => {
    setLoading(true);
    try {
      await updateDoc(doc(db, 'therapists/' + therapist.id), {
        firstName,
        lastName,
      });
      setEventIndicator('success', 'Therapeut erfolgreich umbenannt');
      handleClose();
    } catch (error) {
      setEventIndicator('error', 'Fehler beim Umbenennen des Therapeuten');
      console.error('Error writing document: ', error);
    } finally {
      setLoading(false);
    }
  };

  const noChange = therapist.firstName === firstName && therapist.lastName === lastName;
  const disabled = noChange || !firstName || !lastName;

  if (!therapist) {
    return <Navigate to=".." />;
  }
  return (
    <ModalContainer handleClose={handleClose}>
      <Container>
        <StyledH3>{t('CHANGE-THERAPIST-NAME-TITLE')}</StyledH3>
        <FormContainer>
          <SingleTextInput
            id="firstInput"
            value={firstName}
            onChange={setFirstName}
            placeholder={t('ONBOARDING-PRACTICE-THERAPISTS-SCREEN-THERAPIST-NAME')}
          />
          <SingleTextInput
            value={lastName}
            onChange={setLastName}
            placeholder={t('ONBOARDING-PRACTICE-THERAPISTS-SCREEN-THERAPIST-SURNAME')}
          />
        </FormContainer>
        <ButtonGroup
          textButton={{
            text: t('BACK-BUTTON'),
            onClick: handleClose,
            loading,
          }}
          progressButton={{
            currentStep: 0,
            numberOfSteps: 0,
            text: t('SAVE-BUTTON'),
            onClick: renameTherapist,
            loading,
            disabled,
          }}
        />
      </Container>
    </ModalContainer>
  );
};

const StyledH3 = styled(H3)`
  color: ${colors.Dark};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
