import { getFunctions, httpsCallable } from 'firebase/functions';

export const sendMailVerification = async () => {
  const functions = getFunctions();

  const call = httpsCallable(functions, 'sendDashboardVerification');
  const callableReturnMessage = await call({
    language: 'de',
  });
  console.log(callableReturnMessage);
};
