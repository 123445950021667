import { useNavigate } from 'react-router-dom';
import { getChainOnboardingPaymentType, getChainPractices, getOnboardingFlags } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { ProgressStep } from '../components/OnboardingProgressButton';
import { generatePracticeProgress } from './generateProgressDataOnboarding';

export const generateProgressDataNewPractice = () => {
  const navigate = useNavigate();
  const onboardingFlags = useMainStore(getOnboardingFlags);
  const practices = useMainStore(getChainPractices);
  const samePayment = useMainStore(getChainOnboardingPaymentType) === 'same';
  const newPractices = practices?.filter((practice) => !onboardingFlags?.practices?.[practice.id]?.completed);
  const currentUrl = window.location.pathname;
  const practiceProgress = generatePracticeProgress(newPractices, onboardingFlags, currentUrl, samePayment);

  const mainProgress = [
    {
      title: 'Praxis Hinzufügen',
      navigation: () => navigate('../../practices-names'),
      depth: 0,
      completed: true,
      active: currentUrl.includes('practices-names'),
    },
    ...practiceProgress,
  ] as ProgressStep[];
  return mainProgress;
};
