import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { db } from '../../../firebaseConfig';
import { Therapist } from '../../../types/Practices/TherapistType';

export const useTherapistsConnect = (practiceId?: string) => {
  const [therapists, setTherapists] = useState<Array<Therapist>>();
  useEffect(() => {
    if (!practiceId) return;
    const sub = onSnapshot(
      query(collection(db, 'therapists'), where('practiceId', '==', practiceId)),
      (therapistsSnapshot) => {
        const therapistsData = therapistsSnapshot.docs
          .map((doc) => doc.data() as Therapist)
          .sort((a, b) => a.creationUnix - b.creationUnix);
        setTherapists(therapistsData);
      },
    );
    return sub;
  }, []);

  const activeTherapists = useMemo(() => therapists?.filter((therapist) => !therapist.inactive), [therapists]);
  const inactiveTherapists = useMemo(() => therapists?.filter((therapist) => therapist.inactive), [therapists]);
  return { loadingTherapists: therapists === undefined, therapists, activeTherapists, inactiveTherapists };
};
