import { SupportedLanguages } from '../../types/GeneralType';

export const getDateStringShort = (date: Date) => {
  return date.getFullYear() + '-' + toTwoDigits(date.getMonth() + 1) + '-' + toTwoDigits(date.getDate());
};

export const getDateStringFull = (date: Date) => {
  return (
    date.getFullYear() +
    '-' +
    toTwoDigits(date.getMonth() + 1) +
    '-' +
    toTwoDigits(date.getDate()) +
    ',' +
    toTwoDigits(date.getHours()) +
    ':' +
    toTwoDigits(date.getMinutes()) +
    ':' +
    toTwoDigits(date.getSeconds())
  );
};
export const getDateStringMedium = (date: Date) => {
  return (
    date.getFullYear() +
    '-' +
    toTwoDigits(date.getMonth() + 1) +
    '-' +
    toTwoDigits(date.getDate()) +
    ',' +
    toTwoDigits(date.getHours())
  );
};

export const getCurrentDateStringFull = () => {
  return getDateStringFull(new Date());
};

export const getAirtableDateString = (date: Date) => {
  return (
    date.getDate() +
    '/' +
    (date.getMonth() + 1) +
    '/' +
    date.getFullYear() +
    ' ' +
    toTwoDigits(date.getHours()) +
    ':' +
    toTwoDigits(date.getMinutes())
  );
};

export const toTwoDigits = (number: number) => {
  return ('0' + number).slice(-2);
};

export const readFullDateString = (dateString: string) => {
  const [datePart, timePart] = dateString.split(',');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hours, minutes, seconds] = timePart.split(':').map(Number);
  const parsedDate = new Date(year, month - 1, day, hours, minutes, seconds);
  return parsedDate;
};

export const readMediumDateString = (dateString: string) => {
  const [datePart, hours] = dateString.split(',');
  const [year, month, day] = datePart.split('-').map(Number);
  const parsedDate = new Date(year, month - 1, day, Number(hours));
  return parsedDate;
};

export const readShortDateString = (dateString: string) => {
  const [year, month, day] = dateString.split('-').map(Number);
  const parsedDate = new Date(year, month - 1, day);
  return parsedDate;
};

export const dateDifferenceSeconds = (date1: Date, date2: Date) => {
  return (date1.getTime() - date2.getTime()) / 1000;
};

export const dateDifferenceHours = (date1: Date, date2: Date) => {
  return Math.floor(dateDifferenceSeconds(date1, date2) / 3600);
};

export const dateDifferenceMinutes = (date1: Date, date2: Date) => {
  return Math.floor(dateDifferenceSeconds(date1, date2) / 60);
};

export const dateDifferenceDays = (date1: Date, date2: Date) => {
  return Math.floor(dateDifferenceSeconds(date1, date2) / 86400);
};

export const dateDifferenceWeeks = (date1: Date, date2: Date) => {
  return Math.floor(dateDifferenceSeconds(date1, date2) / 604800);
};

export const dateDifferenceYears = (date1: Date, date2: Date) => {
  return Math.floor(dateDifferenceSeconds(date1, date2) / 31536000);
};

export const getDisplayDateString = (date: Date) => {
  return toTwoDigits(date.getDate()) + '.' + toTwoDigits(date.getMonth() + 1) + '.' + date.getFullYear();
};

export const getDisplayShortDateString = (date: Date, language: SupportedLanguages) => {
  const month = date.toLocaleString(language, { month: 'long' });

  return toTwoDigits(date.getDate()) + '. ' + month;
};

export const dateSubtractDays = (date: Date, days: number) => {
  const dateCopy = new Date(date);
  dateCopy.setDate(dateCopy.getDate() - days);
  return dateCopy;
};

export const dateAddHours = (date: Date, hours: number) => {
  const dateCopy = new Date(date);
  dateCopy.setHours(dateCopy.getHours() + hours);
  return dateCopy;
};

export const dateIsToday = (date: Date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

// https://weeknumber.com/how-to/javascript#:~:text=To%20get%20the%20ISO%20week,getWeekYear()%20.
export const dateCalendarWeek = (date: Date) => {
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
};

export const dateSetWeekDay = (date: Date, weekDay: number) => {
  const currentDay = date.getDay();
  const currentDayStartMonday = (currentDay + 6) % 7;
  // 0-6: lastWeek => 7-13: currentWeek => 14-20 => nextWeek
  const distance = weekDay - 7 - currentDayStartMonday;
  date.setDate(date.getDate() + distance);
  return date;
};

export const getAge = (date: Date) => {
  // calculate month difference from current date in time
  const monthDiff = Date.now() - date.getTime();

  // convert the calculated difference in date format
  const ageDt = new Date(monthDiff);

  // extract year from date
  const year = ageDt.getUTCFullYear();

  // now calculate the age of the user
  const age = Math.abs(year - 1970);
  return age;
};
