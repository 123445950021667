import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { GenericSvg } from '../../../assets/svgs/GenericSVG';
import { CustomButton } from '../../../core/components/buttons/CustomButton';
import { hexWithOpacity } from '../../../core/utils/hexWithOpacity';
import { colors } from '../../../styles/colors';
import { Body, H3 } from '../../../styles/textStyles';

export type Props = {
  title: string;
  content: string;
  active?: boolean;
  children?: React.ReactNode;
};

export const CheckCard = ({ active, title, content, children }: Props) => {
  const [extended, setExtended] = useState(!active);
  const contentRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number | undefined>(undefined);
  const [bottomHeight, setBottomHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(extended ? contentRef.current.scrollHeight : 0);
    }
    if (bottomRef.current) {
      setBottomHeight(extended ? bottomRef.current.scrollHeight : 0);
    }
  }, [extended, content]);

  const handleExtended = () => {
    setExtended((prev) => !prev);
  };

  return (
    <Container $active={active}>
      <TopContainer onClick={active ? handleExtended : undefined} $extended={extended}>
        <HeadlineContainer>
          {
            <GenericSvg
              name={active ? 'Checkmark' : 'CheckBoxRoundInactive'}
              primaryColor={active ? colors.White : colors.Primary}
              size={24}
            />
          }
          <Headline $active={active}>{title}</Headline>
          {active && (
            <RotatingIconButton
              name={'ArrowDown'}
              primaryColor={active ? colors.White : colors.Primary}
              size={24}
              $isRotated={extended}
            />
          )}
        </HeadlineContainer>
        <MainText $active={active} ref={contentRef} style={{ height: contentHeight }}>
          {content}
        </MainText>
      </TopContainer>
      <BottomContainer $active={active} $extended={extended} style={{ height: bottomHeight }} ref={bottomRef}>
        <BottomContent>{children}</BottomContent>
      </BottomContainer>
    </Container>
  );
};

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

const TopContainer = styled(CustomButton)<{ $extended: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 30px;
  gap: ${({ $extended }) => ($extended ? 10 : 0)}px;
  padding-bottom: 10px;
`;

const Container = styled.div<{ $active?: boolean }>`
  background-color: ${({ $active }) => ($active ? colors.Primary : colors.White)};
  width: 100%;
  max-width: 600px;
  border: 2px solid ${({ $active }) => ($active ? colors.Primary : colors.Light)};
  border-radius: 20px;
`;

const Headline = styled(H3)<{ $active?: boolean }>`
  color: ${({ $active }) => ($active ? colors.White : colors.Medium)};
  text-align: left;
  flex: 1;
`;

const MainText = styled(Body)<{ $active?: boolean }>`
  color: ${({ $active }) => ($active ? colors.White : colors.Medium)};
  padding-left: 48px;
  text-align: left;
  overflow: hidden;
  transition:
    height 0.3s ease-in-out,
    padding-top 0.3s ease-in-out;
`;

const BottomContainer = styled.div<{ $active?: boolean; $extended: boolean }>`
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  height: ${({ $extended }) => ($extended ? 'auto' : '0')};
  margin: 0px 30px;
  border-top: ${({ $extended }) => ($extended ? 2 : 0)}px solid
    ${({ $active }) => ($active ? hexWithOpacity(colors.White, 0.2) : colors.Light)};
  padding-top: ${({ $active }) => ($active ? 10 : 20)}px;
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-bottom: 30px;
`;

const RotatingIconButton = styled(GenericSvg)<{ $isRotated: boolean }>`
  transform: rotate(${(props) => (props.$isRotated ? '180deg' : '0deg')});
  transition: transform 0.3s ease;
  margin-top: 5px;
`;
