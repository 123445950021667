import { onIdTokenChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { handleLogout } from '../../core/utils/handleLogout';
import { useMainStore } from '../../store/mainStore';
import { getUserMailToChangeTo } from '../../store/userSlice';

export const useDetectEmailChange = () => {
  // Detects if the user changes their email and logs them out if they do
  const emailToChangeTo = useMainStore(getUserMailToChangeTo);
  const [initialEmailToChangeTo, setInitialEmailToChangeTo] = useState<string | undefined>(emailToChangeTo);
  useEffect(() => {
    if (emailToChangeTo) {
      setInitialEmailToChangeTo(emailToChangeTo);
    } else if (!emailToChangeTo && initialEmailToChangeTo) {
      handleLogout();
    }
    onIdTokenChanged;
  }, [emailToChangeTo, initialEmailToChangeTo]);
};
