import styled from 'styled-components';
import { H4 } from '../../../styles/textStyles';
import { DrawerNavItem } from './DrawerNavItem';

type Props = {
  headline: string;
  items: { title: string; path: string }[];
  toggleDrawer: () => void;
};

export const DrawerCategory = ({ headline, items, toggleDrawer }: Props) => (
  <Category>
    <StyledH4>{headline}</StyledH4>
    <Items>
      {items.map((item) => (
        <DrawerNavItem key={item.path} {...item} closeDrawer={toggleDrawer} />
      ))}
    </Items>
  </Category>
);

const Category = styled.div``;

const StyledH4 = styled(H4)``;

const Items = styled.div`
  padding-left: 20px;
`;
