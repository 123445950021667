import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { getUserChainId, getUserId } from '../../../store/userSlice';

export const safeOnboardingFeedback = async (onboardingFeedback: { feedback?: string; grade?: number }) => {
  const state = useMainStore.getState();
  const userId = getUserId(state);
  const chainId = getUserChainId(state);
  await setDoc(
    doc(db, 'practiceUsers/' + userId + '/documents/practiceUserOnboardingFeedback'),
    {
      userId,
      chainId,
      ...onboardingFeedback,
    },
    { merge: true },
  );
};
