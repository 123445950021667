import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { GenericSvg } from '../../../assets/svgs/GenericSVG';
import { ButtonGroup } from '../../../core/components/buttons/ButtonGroup';
import { InfoText } from '../../../core/components/text/InfoText';
import { colors } from '../../../styles/colors';

type Props = {
  type: '404' | 'Error';
};
export const ErrorOverlay = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showNewMessage } = useIntercom();
  const openIntercom = () => {
    showNewMessage();
  };
  console.log('props', props);
  const isError = props.type === 'Error';

  const handleAction = () => {
    if (isError) {
      window.location.reload();
    } else {
      navigate('/', { replace: true });
    }
  };
  return (
    <OuterContainer>
      <Container>
        <GenericSvg name="FailureIcon" primaryColor={colors.Primary} size={50} />
        <InfoText
          headline={isError ? t('ERROR-OVERLAY-TITLE') : t('404-OVERLAY-TITLE')}
          text={[isError ? t('ERROR-OVERLAY-SUBTITLE') : t('404-OVERLAY-SUBTITLE')]}
        />
        <ButtonGroup
          progressButton={{
            text: t('ERROR-OVERLAY-CONTACT-BUTTON'),
            onClick: openIntercom,
            numberOfSteps: 1,
            currentStep: 1,
          }}
          textButton={{
            text: isError ? t('ERROR-OVERLAY-REFRESH-BUTTON') : t('404-OVERLAY-REFRESH-BUTTON'),
            onClick: handleAction,
          }}
        />
      </Container>
    </OuterContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  max-width: 640px;
  gap: 20px;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
