import { useEffect } from 'react';

export const useHandleOnSubmitKeyPress = (handleNext: () => void) => {
  useEffect(() => {
    //on component mount focus on first input
    document.getElementById('firstInput')?.focus();

    const handleOnSubmitKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleNext();
      }
    };
    window.addEventListener('keypress', handleOnSubmitKeyPress);
    return () => {
      window.removeEventListener('keypress', handleOnSubmitKeyPress);
    };
  }, []);
};
