import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ProgressButton } from '../../../core/components/buttons/ProgressButton.tsx';
import { LoadingSpinner } from '../../../core/components/loading/LoadingSpinner.tsx';
import { InfoText } from '../../../core/components/text/InfoText.tsx';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput.tsx';
import { Header } from '../../../navigation/components/Header.tsx';
import { colors } from '../../../styles/colors.ts';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { readUrlVerification } from '../utils/firebaselVerification.ts';
import { resetPassword } from '../utils/resetPassword.ts';

export const EmailVerificationScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [response, setResponse] = useState<'INVALID_URL' | 'SUCCESS'>();
  const [type, setType] = useState<string | undefined>();
  const [passwordError, setPasswordError] = useState<string | undefined>();
  const [newPassword, setNewPassword] = useState('');
  const [passwordLoading, setPasswordLoading] = useState(false);

  const initialized = useRef(false);
  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;
    readUrlVerification().then((newResponse) => {
      if (response === undefined) {
        setResponse(newResponse.status);
        setType(newResponse.type);
      }
    });
  }, []);

  const changePassword = async () => {
    if (newPassword.length < 8) {
      setPasswordError(t('ONBOARDING-ERROR-INVALID-PASSWORD-LENGTH'));
      return;
    }
    setPasswordError('');
    setPasswordLoading(true);
    try {
      const result = await resetPassword(newPassword);
      if (result === 'SUCCESS') {
        navigate('/welcome/login', { state: { changedPassword: true } });
      } else {
        setPasswordError(t('ONBOARDING-ERROR-PASSWORD-RESET-URL'));
      }
    } catch (e) {
      console.log(e);
      setPasswordError(t('ONBOARDING-ERROR-PASSWORD-RESET-GENERAL'));
    } finally {
      setPasswordLoading(false);
    }
  };

  const currentStep = newPassword.length > 0 ? 1 : 0;

  const loadingError = response === 'INVALID_URL';
  let title = loadingError ? t('ONBOARDING-EMAIL-VERIFIED-ERROR-TITLE') : t('ONBOARDING-EMAIL-VERIFIED-SUCCESS-TITLE');
  let text = loadingError ? [t('ONBOARDING-EMAIL-VERIFIED-ERROR-TEXT')] : [t('ONBOARDING-EMAIL-VERIFIED-SUCCESS-TEXT')];

  if (type === 'resetPassword') {
    title = t('ONBOARDING-CHOOSE-NEW-PASSWORD-TITLE');
    text = [t('ONBOARDING-CHOOSE-NEW-PASSWORD-SUBTITLE')];
  }

  return (
    <OutletContainer>
      <Header />
      <OnboardingScreenContainer hideProgress hideBackButton>
        {!response ? <LoadingSpinner color={colors.Primary} /> : <InfoText headline={title} text={text} />}
        {type === 'resetPassword' && !loadingError && (
          <>
            <SingleTextInput
              value={newPassword}
              onChange={setNewPassword}
              placeholder={t('ONBOARDING-CHOOSE-NEW-PASSWORD-PASSWORD-FIELD')}
              error={passwordError}
              type={'password'}
            />
            <ButtonContainer>
              <ProgressButton
                text={t('ONBOARDING-BUTTON-CHANGE-PASSWORD')}
                currentStep={currentStep}
                numberOfSteps={1}
                onClick={changePassword}
                loading={passwordLoading}
              />
            </ButtonContainer>
          </>
        )}
      </OnboardingScreenContainer>
    </OutletContainer>
  );
};

const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  padding-top: 100px;
  padding-bottom: 200px;
  background-color: 'red';
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
