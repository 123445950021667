import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from 'styled-components';
import { TagSelection } from '../../../core/components/tags/TagSelection';
import { colors } from '../../../styles/colors';
import { Body, H3 } from '../../../styles/textStyles';

import { useParams } from 'react-router-dom';
import { TextButton } from '../../../core/components/buttons/TextButton';
import { ContentContainer } from '../../../core/components/container/ContentContainer';
import { usePayoutDocumentsConnect } from '../../../core/utils/connectFirestore/usePayoutDocumentsConnect';
import { useMainStore } from '../../../store/mainStore';
import { PracticePayoutInfo } from '../../../types/Administration/PracticePayoutType';
import { downloadAllPayoutPDFs } from '../utils/downloadAllPayoutPDFs';
import { PayoutCell } from './PayoutCell';

export const PayoutHistory = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const practiceId = useParams().practiceId;
  const { loadingData, payoutFilterYears, payoutDocumentsPerYear } = usePayoutDocumentsConnect(practiceId);
  const [selectedYear, setSelectedYear] = useState(payoutFilterYears?.[0]?.value);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const selectedPayouts: PracticePayoutInfo[] =
    payoutDocumentsPerYear?.[selectedYear]?.sort((a, b) => b.date - a.date) ?? [];

  useEffect(() => {
    if (!selectedYear && payoutFilterYears && payoutFilterYears.length > 0) {
      setSelectedYear(payoutFilterYears[0].value ?? '');
    }
  }, [payoutFilterYears]);

  const downloadPayouts = async () => {
    if (!payoutDocumentsPerYear) return;
    setLoadingDownload(true);
    try {
      await downloadAllPayoutPDFs(payoutDocumentsPerYear);
    } catch (error) {
      console.error('Error downloading the PDF:', error);
      setEventIndicator('error', 'Fehler beim Herunterladen');
    } finally {
      setEventIndicator('success', 'Download erfolgreich');
      setLoadingDownload(false);
    }
  };

  const hasPayouts = selectedPayouts.length > 0 && !loadingData;

  return (
    <ContentContainer>
      <Headline>
        <StyledH3>Deine bisherigen Gutschriften </StyledH3>
      </Headline>
      {loadingData ? (
        <SkeletonWrapper>
          <Skeleton height={40} />
        </SkeletonWrapper>
      ) : (
        <TagSelection
          tags={payoutFilterYears}
          selected={[selectedYear]}
          setSelected={(value) => setSelectedYear(value?.[0] as string)}
          type={'singleSelect'}
        />
      )}
      {hasPayouts && <Separator />}
      {loadingData ? (
        <SkeletonWrapper>
          <Skeleton count={2} height={80} style={{ marginBottom: '10px' }} />
        </SkeletonWrapper>
      ) : hasPayouts ? (
        selectedPayouts.map((payout) => <PayoutCell key={payout.payoutId} data={payout} />)
      ) : (
        <StyledBody>{'Es wurden noch keine Gutschriften gespeichert.'}</StyledBody>
      )}
      {hasPayouts && (
        <BottomContainer>
          <TextButton onClick={downloadPayouts} text="Alle herunterladen (PDFs)" loading={loadingDownload} />
        </BottomContainer>
      )}
    </ContentContainer>
  );
};

const StyledH3 = styled(H3)`
  color: ${colors.Medium};
`;

const Headline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const Separator = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${colors.Light};
`;

const StyledBody = styled(Body)`
  color: ${colors.Medium};
`;

const SkeletonWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
