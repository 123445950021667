import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ProgressButton } from '../../../core/components/buttons/ProgressButton';
import { editOwner } from '../../../core/utils/editFirestore/editOwner';
import { useMainStore } from '../../../store/mainStore';
import { getUser } from '../../../store/userSlice';
import { colors } from '../../../styles/colors';
import { H3 } from '../../../styles/textStyles';
import { PersonalInfoForm } from '../../onboarding/components/PersonalInfoForm';

export const PersonalDataComponent = () => {
  const { t } = useTranslation();
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const [loading, setLoading] = useState(false);
  const userData = useMainStore(getUser);

  const [fields, setFields] = useState({
    firstName: userData?.privateInfo?.firstName ?? '',
    lastName: userData?.privateInfo?.lastName ?? '',
    phoneNumber: userData?.privateInfo?.phoneNumber ?? '',
    gender: userData?.privateInfo?.gender ?? '',
  });

  const user = useMainStore(getUser)?.privateInfo;
  const missingData = !fields.firstName || !fields.lastName || !fields.phoneNumber || !fields.phoneNumber;
  const noChanges =
    fields.firstName === user?.firstName &&
    fields.lastName === user?.lastName &&
    fields.phoneNumber === user?.phoneNumber &&
    fields.gender === user?.gender;
  const buttonDisabled = missingData || noChanges;

  const handleSave = async () => {
    try {
      setLoading(true);
      if (buttonDisabled) return;
      await editOwner({
        'privateInfo.firstName': fields.firstName,
        'privateInfo.lastName': fields.lastName,
        'privateInfo.phoneNumber': fields.phoneNumber,
        'privateInfo.gender': fields.gender,
      });
      setEventIndicator('success', 'Daten gespeichert');
    } catch (e) {
      setEventIndicator('error', 'Fehler beim Speichern der Daten');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <StyledH3>Persönliche Daten</StyledH3>
      <PersonalInfoForm fields={fields} setFields={setFields} loadingData={!userData} />
      <ButtonContainer>
        <ProgressButton onClick={handleSave} disabled={buttonDisabled} text={t('SAVE-BUTTON')} loading={loading} />
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;

const StyledH3 = styled(H3)`
  color: ${colors.Medium};
`;
