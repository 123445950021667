import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { getFirebaseUserId } from '../../../store/userSlice';

export const editOwner = async (newData: { [key: string]: any }) => {
  const state = useMainStore.getState();
  const userId = getFirebaseUserId(state);
  await updateDoc(doc(db, 'practiceUsers/' + userId), newData);
};
