import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { GenericSvg } from '../../../assets/svgs/GenericSVG';
import { colors } from '../../../styles/colors';
import { H3 } from '../../../styles/textStyles';
import { hexWithOpacity } from '../../utils/hexWithOpacity';
import { CustomButton } from './CustomButton';

type CounterButtonProps = {
  value: number;
  setValue: (value: number) => void;
  minValue?: number;
  maxValue?: number;
  stepSize?: number;
  color?: string;
};

export const CounterButton: React.FC<CounterButtonProps> = ({
  value,
  setValue,
  minValue = 0,
  maxValue = 99,
  stepSize = 1,
  color = colors.Primary,
}) => {
  const longPressDelay = 500;
  const longPressInterval = 150;
  const longPressTimer = useRef<NodeJS.Timeout | null>(null);
  const intervalTimer = useRef<NodeJS.Timeout | null>(null);
  const localValue = useRef(value);

  const updateValue = useCallback(
    (increment: boolean) => {
      const newValue = increment
        ? Math.min(maxValue, localValue.current + stepSize)
        : Math.max(minValue, localValue.current - stepSize);
      localValue.current = newValue;
      setValue(newValue);
    },
    [value, setValue, minValue, maxValue, stepSize],
  );

  const handleLongPress = useCallback(
    (increment: boolean) => {
      updateValue(increment);
      intervalTimer.current = setInterval(() => {
        updateValue(increment);
      }, longPressInterval);
    },
    [updateValue, longPressInterval],
  );

  const startLongPress = useCallback(
    (increment: boolean) => {
      longPressTimer.current = setTimeout(() => {
        handleLongPress(increment);
      }, longPressDelay);
    },
    [handleLongPress, longPressDelay],
  );

  const stopLongPress = useCallback(() => {
    if (longPressTimer.current) {
      clearTimeout(longPressTimer.current);
    }
    if (intervalTimer.current) {
      clearInterval(intervalTimer.current);
    }
  }, []);

  const handleMouseDown = useCallback(
    (increment: boolean) => {
      updateValue(increment);
      startLongPress(increment);
    },
    [updateValue, startLongPress],
  );

  const handleMouseUp = useCallback(() => {
    stopLongPress();
  }, [stopLongPress]);

  return (
    <CounterContainer $color={color}>
      <Button
        onMouseDown={() => handleMouseDown(false)}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={() => handleMouseDown(false)}
        onTouchEnd={handleMouseUp}
        disabled={localValue.current <= minValue}
      >
        <GenericSvg name="Minus" primaryColor={colors.White} size={24} />
      </Button>
      <CountDisplay>
        <StyledH3>{localValue.current}</StyledH3>
      </CountDisplay>
      <Button
        onMouseDown={() => handleMouseDown(true)}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={() => handleMouseDown(true)}
        onTouchEnd={handleMouseUp}
        disabled={localValue.current >= maxValue}
      >
        <GenericSvg name="Plus" primaryColor={colors.White} size={24} />
      </Button>
    </CounterContainer>
  );
};

const StyledH3 = styled(H3)`
  color: ${colors.White};
  text-align: center;
`;

const CounterContainer = styled.div<{ $color: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.$color};
  border-radius: 25px;
  width: 160px;
  box-sizing: border-box;
`;

const Button = styled(CustomButton)`
  border: none;
  color: ${colors.White};
  padding: 10px;
  flex: 2;
  &:focus {
    outline: none;
  }
`;

const CountDisplay = styled.span`
  color: white;
  flex: 3;
  border-left: 1px solid ${hexWithOpacity(colors.White, 0.5)};
  border-right: 1px solid ${hexWithOpacity(colors.White, 0.5)};
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
