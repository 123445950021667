import { useEffect, useState } from 'react';
import { DESKTOP_BREAKPOINT_NUMBER } from '../../styles/breakpoints';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const isMobileWidth = windowDimensions.width < DESKTOP_BREAKPOINT_NUMBER;
  return { ...windowDimensions, isMobileWidth };
};
