import { useParams } from 'react-router-dom';
import { useTherapistsConnect } from '../../../core/utils/connectFirestore/useTherapistsConnect';
import { ActiveTherapists } from './ActiveTherapists';
import { InactiveTherapists } from './InactiveTherapists';

export const PracticeSettingsTherapists = () => {
  const { practiceId } = useParams();

  const { activeTherapists, inactiveTherapists } = useTherapistsConnect(practiceId);

  return (
    <>
      <ActiveTherapists therapists={activeTherapists} />
      <InactiveTherapists therapists={inactiveTherapists} />
    </>
  );
};
