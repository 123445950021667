import { applyActionCode, getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
export const readUrlVerification = async () => {
  // Get the action code from the query parameter.

  console.log('asd');

  // Get the action to complete.
  const mode = getParameterByName('mode');
  const actionCode = getParameterByName('oobCode');
  const userId = getParameterByName('userId');
  console.log('mode:', mode);
  console.log('actionCode:', actionCode);
  console.log('userId:', userId);

  // Configure the Firebase SDK.
  // This is the minimum configuration required for the API to be used.

  const auth = getAuth();
  let result;
  // Handle the user management action.
  if (!mode || !actionCode) {
    return { status: 'INVALID_URL', type: mode };
  }
  try {
    if (mode === 'verifyEmail') {
      result = await handleVerifyEmail(auth, actionCode);
      return { status: result, type: mode };
    } else if (mode === 'verifyAndChangeEmail') {
      result = await handleVerifyEmail(auth, actionCode);
      if (userId) {
        updateEmailToChangeTo(userId);
      }
      return { status: result, type: actionCode };
    } else if (mode === 'resetPassword') {
      return { status: 'SUCCESS', type: mode };
    } else {
      return { status: 'INVALID_URL', type: mode };
    }
  } catch (error: any) {
    console.log('Error:', error);
    return { status: 'INVALID_URL', type: mode };
  }
};

export const getParameterByName = (name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const handleVerifyEmail = async (auth, actionCode) => {
  try {
    await applyActionCode(auth, actionCode);
    return 'SUCCESS';
  } catch (error) {
    throw new Error('Error occurred while verifying mail:' + error);
  }
};

const updateEmailToChangeTo = async (userId: string) => {
  const functions = getFunctions();
  const call = httpsCallable(functions, 'changeDashboardEmailAddressSuccessCallback');
  const callableReturnMessage = await call({
    userId,
  });
  console.log(callableReturnMessage);
};
