import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import {useMainStore} from '../core/store/mainStore';
// import {getUserAppLanguage} from '../core/store/userSlice';
import de from './de.json';
import en from './en.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const initI18n = () => {
  const resources = {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
  };

  i18n
    .use(initReactI18next)
    .use(languageDetectorPlugin as any)
    .init({
      compatibilityJSON: 'v3',
      returnNull: false,
      resources,
      //language to use if translations in user language are not available
      fallbackLng: 'de',
      interpolation: {
        escapeValue: false, // not needed for react!!
      },
    });
};

const languageDetectorPlugin = {
  type: 'languageDetector',
  async: true,
  init: () => {},
  detect: function (callback: (lang: string) => void) {
    try {
      // const deviceLang = getUserAppLanguage(state);
      const deviceLang = 'de';
      callback(deviceLang);
    } catch (error) {
      console.log('Error reading language', error);
    }
  },
  cacheUserLanguage: () => {},
};

export default initI18n;
