import styled from 'styled-components';
import { Body60, H2, H3 } from '../../../styles/textStyles';
('../../styles/textStyles');

type Props = {
  headline?: string;
  text?: string[];
  preHeadline?: string;
  ref?: React.Ref<HTMLDivElement>;
};

export const InfoText = ({ preHeadline, headline, text, ref }: Props) => {
  return (
    <ContainerWith20Gap ref={ref}>
      {preHeadline && <StyledH3>{preHeadline}</StyledH3>}
      <H2>{headline}</H2>
      {text && (
        <ContainerWith30Gap>{text?.map((text, index) => <Body60 key={index}>{text}</Body60>)}</ContainerWith30Gap>
      )}
    </ContainerWith20Gap>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainerWith20Gap = styled(Container)`
  gap: 20px;
  width: 100%;
`;

const ContainerWith30Gap = styled(Container)`
  gap: 30px;
`;

const StyledH3 = styled(H3)`
  opacity: 0.6;
`;
