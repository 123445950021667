import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import SalesContact from '../../../assets/images/SalesContact.jpg';
import { GenericSvg } from '../../../assets/svgs/GenericSVG';
import { CustomButton } from '../../../core/components/buttons/CustomButton';
import { getInvitationData } from '../../../store/invitationSlice';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { TextButtonText } from '../../../styles/textStyles';

export const CheckoutContactButton = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const invitationData = useMainStore(getInvitationData);
  const phoneNumber = invitationData?.hubspotData?.ownerData?.phoneNumber ?? '+49 89 38030181';
  const profileImage = invitationData?.hubspotData?.ownerData?.profileImageUrl ?? SalesContact;
  const { showNewMessage, isOpen, hide } = useIntercom();
  const openIntercom = () => {
    if (!isOpen) {
      showNewMessage();
    } else {
      hide();
    }
  };

  const handlePhone = () => {
    const isMobile = /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (isMobile) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      try {
        navigator.clipboard.writeText(phoneNumber);
        setEventIndicator('success', 'Nummer in die Zwischenablage kopiert');
      } catch (error) {
        console.error('Failed to copy to clipboard', error);
        setEventIndicator('error', 'Fehler beim Kopieren');
      }
    }
  };
  return (
    <OuterContainer>
      <ClickContainer onClick={openIntercom}>
        <GenericSvg name="Chat" size={24} color={colors.Medium} />
        <Text>{'Kontakt per Chat'}</Text>
      </ClickContainer>
      <ClickContainer onClick={handlePhone}>
        <Avatar src={profileImage} alt="SalesContact" />
        <Text>{phoneNumber}</Text>
      </ClickContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 180px;
`;
const ClickContainer = styled(CustomButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const Text = styled(TextButtonText)`
  text-decoration-line: underline;
`;
