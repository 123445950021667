import { getFunctions, httpsCallable } from 'firebase/functions';
import { getChainInvitationId, getIsAddNewPractice } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';

export const createSubscription = async (practiceIds: string[], chainId: string, practiceIndex: number) => {
  try {
    const functions = getFunctions();
    const state = useMainStore.getState();
    const invitationId = getChainInvitationId(state);
    const isAddNewPractice = getIsAddNewPractice(state);
    const isFirstPractice = practiceIndex === 0 && !isAddNewPractice;
    functions.region = 'europe-west1';
    const call = httpsCallable(functions, 'createScheduledSubscription');
    const callResult = (await call({
      practiceIds: practiceIds,
      invitationId,
      chainId,
      firstPractice: isFirstPractice,
      isAddNewPractice,
    })) as any;
    const resultData = callResult.data;
    if (resultData.status === 'success') {
      return resultData;
    } else {
      throw new Error('Subscription creation failed' + resultData.message);
    }
  } catch (error) {
    throw error;
  }
};
