import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { PracticePayoutInfo } from '../../../types/Administration/PracticePayoutType';

export const downloadAllPayoutPDFs = async (data: { [key: string]: PracticePayoutInfo[] }) => {
  const zip = new JSZip();

  for (const [year, payouts] of Object.entries(data)) {
    const yearFolder = zip.folder(year);
    if (!yearFolder) continue;

    for (const payout of payouts) {
      try {
        if (!payout.downloadUrl) {
          throw new Error('No download URL found');
        }
        const response = await fetch(payout.downloadUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();

        const date = new Date(payout.date);
        const quartal = Math.floor(date.getMonth() / 3) + 1;
        const filename = `${year}_Q${quartal}_Physiofit-Gutschrift.pdf`;

        yearFolder.file(filename, blob);
      } catch (error) {
        console.error(`Error downloading PDF for ${year}:`, error);
        throw error;
        // You might want to show an error message to the user here
      }
    }
  }

  try {
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, 'Physiofit-Gutschriften.zip');
  } catch (error) {
    console.error('Error creating zip file:', error);
    throw error;
    // You might want to show an error message to the user here
  }
};
