import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LoadingSpinner } from '../core/components/loading/LoadingSpinner';
import { TagSelection } from '../core/components/tags/TagSelection';
import { InfoText } from '../core/components/text/InfoText';
import { MainScreenContainer } from '../features/overview/components/MainScreenComponent';
import { getChainPracticeName } from '../store/chainSlice';
import { useMainStore } from '../store/mainStore';
import { colors } from '../styles/colors';

export const PracticeSettingsLayout = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { practiceId } = useParams();
  const practiceName = useMainStore((state) => getChainPracticeName(state, practiceId));
  const [loading, setLoading] = useState(false);
  const currentLocation = window.location.pathname.split('/')[3];

  const handleNavigate = async (newTarget: string) => {
    if (currentLocation === newTarget) return;
    setLoading(true);
    try {
      navigate('./' + newTarget);
    } catch (error) {
      console.error(error);
      setEventIndicator('error', 'Fehler beim Navigieren');
    } finally {
      setLoading(false);
    }
  };

  const options = [
    { text: 'Team', value: 'team' },
    { text: 'Gutschrift', value: 'payout' },
    { text: 'Rechnung', value: 'invoice' },
  ];

  return (
    <MainScreenContainer>
      <SettingsSelection>
        <InfoText preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName })} />
        <TagSelection
          selected={[currentLocation]}
          tags={options}
          setSelected={(value) => {
            handleNavigate(value?.[0] as string);
          }}
          type={'singleSelect'}
          atLeastOne={true}
        />
      </SettingsSelection>
      {loading ? <LoadingSpinner color={colors.Primary} /> : <Outlet />}
    </MainScreenContainer>
  );
};

const SettingsSelection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;
