import { doc, setDoc } from 'firebase/firestore';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ButtonGroup } from '../../../core/components/buttons/ButtonGroup';
import { ModalContainer } from '../../../core/components/container/ModalContainer';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { getUserChainId } from '../../../store/userSlice';
import { colors } from '../../../styles/colors';
import { H3 } from '../../../styles/textStyles';

export const TherapistSettingsAddModal = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { practiceId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const chainId = useMainStore(getUserChainId);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    navigate('..');
  };

  const createTherapist = async () => {
    setLoading(true);
    try {
      const id = uuidv4();
      await setDoc(
        doc(db, 'therapists/' + id),
        {
          firstName: firstName,
          lastName: lastName,
          id: id,
          practiceId: practiceId,
          chainId,
          creationUnix: Date.now(),
        },
        { merge: true },
      );
      setEventIndicator('success', 'Therapeut erfolgreich erstellt');
      handleClose();
    } catch (error) {
      console.error('Error writing document: ', error);
      setEventIndicator('error', 'Fehler beim Erstellen des Therapeuten');
    } finally {
      setLoading(false);
    }
  };

  let currentStep = lastName ? (firstName ? 2 : 1) : firstName ? 1 : 0;

  return (
    <ModalContainer handleClose={handleClose}>
      <Container>
        <StyledH3>{t('ADD-THERAPISTS-MODAL-TITLE')}</StyledH3>
        <FormContainer>
          <SingleTextInput
            id="firstInput"
            value={firstName}
            onChange={setFirstName}
            placeholder={t('ONBOARDING-PRACTICE-THERAPISTS-SCREEN-THERAPIST-NAME')}
          />
          <SingleTextInput
            value={lastName}
            onChange={setLastName}
            placeholder={t('ONBOARDING-PRACTICE-THERAPISTS-SCREEN-THERAPIST-SURNAME')}
          />
        </FormContainer>
        <ButtonGroup
          textButton={{
            text: t('BACK-BUTTON'),
            onClick: handleClose,
            loading,
          }}
          progressButton={{
            currentStep,
            numberOfSteps: 2,
            text: t('SAVE-BUTTON'),
            onClick: createTherapist,
            loading,
          }}
        />
      </Container>
    </ModalContainer>
  );
};

const StyledH3 = styled(H3)`
  color: ${colors.Dark};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
