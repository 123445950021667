import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { ButtonText } from '../../../styles/textStyles';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { CustomButton } from './CustomButton';

type Props = {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  color?: string;
  className?: string;
};

export const MainButton = (props: Props) => {
  return (
    <StyledCustomButton
      onClick={props.onClick}
      disabled={props.disabled}
      $color={props.color}
      className={props.className}
    >
      {props.loading ? <LoadingSpinner /> : <StyledButtonText>{props.text}</StyledButtonText>}
    </StyledCustomButton>
  );
};

const StyledCustomButton = styled(CustomButton)<{ $color?: string }>`
  padding: 0 20px;
  border-radius: 100px;
  height: 50px;
  min-width: 200px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: ${(props) => props.$color || colors.Primary};
`;

const StyledButtonText = styled(ButtonText)`
  color: ${colors.White};
  position: relative;
  z-index: 1;
`;
