import { styled } from 'styled-components';
import { colors } from '../../../styles/colors';

type Props = {
  children: React.ReactNode;
};

export const ScreenContainer = ({ children }: Props) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: ${colors.Background};
`;
