import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonGroup } from '../../../core/components/buttons/ButtonGroup';
import { ModalContainer } from '../../../core/components/container/ModalContainer';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { H3 } from '../../../styles/textStyles';
import { checkIfValidEmail } from '../../onboarding/utils/checkIfValidMail';
import { sendChangeEmailLink } from '../utils/sendChangeEmailLink';

export const ChangeEmailModal = () => {
  const { t } = useTranslation();
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const [newEmail, setNewEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('..');
  };

  const handleSubmit = async () => {
    setErrorEmail('');
    setLoading(true);
    try {
      if (!checkIfValidEmail(newEmail)) {
        setErrorEmail(t('ONBOARDING-REGISTER-ERROR-EMAIL-NOT-VALID'));
        return;
      }
      const result = await sendChangeEmailLink(newEmail);
      if (result?.data?.status === 'error') {
        setErrorEmail(t('ONBOARDING-REGISTER-ERROR-UNKNOWN'));
        setEventIndicator('error', t('CHANGE-EMAIL-ERROR'));
        return;
      }
      setEventIndicator('success', t('CHANGE-EMAIL-EMAIL-SENT'));
      navigate('..');
    } catch (e) {
      console.log(e);
      setEventIndicator('error', t('CHANGE-EMAIL-ERROR'));
      setErrorEmail(t('ONBOARDING-REGISTER-ERROR-UNKNOWN'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <Container>
        <StyledH3>{t('CHANGE-EMAIL-BUTTON')}</StyledH3>
        <FormContainer>
          <SingleTextInput
            id="firstInput"
            value={newEmail}
            onChange={setNewEmail}
            placeholder={t('NEW-EMAIL-PLACEHOLDER')}
            error={errorEmail}
            disabled={loading}
          />
        </FormContainer>
        <ButtonGroup
          progressButton={{
            text: t('CHANGE-EMAIL-BUTTON'),
            onClick: handleSubmit,
            loading: loading,
          }}
          textButton={{
            text: t('BACK-BUTTON'),
            onClick: handleClose,
            disabled: loading,
          }}
        />
      </Container>
    </ModalContainer>
  );
};

const StyledH3 = styled(H3)`
  color: ${colors.Dark};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 520px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
