import { styled } from 'styled-components';

type Props = {
  children: React.ReactNode;
};

export const ContentContainer = ({ children }: Props) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 20px;
  width: 100%;
`;
