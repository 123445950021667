import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getChainOnboardingPaymentType, getOnboardingFlags } from '../../store/chainSlice';
import { useMainStore } from '../../store/mainStore';
import { getEmailVerified, getUserIsLoggedIn } from '../../store/userSlice';
import { PracticesOnboardingFlags } from '../../types/Practices/ChainType';

export function useOnboardingRedirect() {
  const navigate = useNavigate();
  const emailVerified = useMainStore(getEmailVerified);
  const onboardingFlags = useMainStore(getOnboardingFlags);
  const userLoggedIn = useMainStore(getUserIsLoggedIn);
  const onboardingPaymentType = useMainStore(getChainOnboardingPaymentType);
  useEffect(() => {
    if (!userLoggedIn) {
      navigate('../');
    } else if (!emailVerified) {
      console.log('emailVerified', emailVerified);
      navigate('/onboarding/verification');
    } else if (!onboardingFlags) {
      navigate('/onboarding/basic-info');
    } else if (!onboardingFlags.basicInfo) {
      navigate('/onboarding/basic-info');
    } else if (!onboardingFlags.referral) {
      navigate('/onboarding/referral');
    } else if (!onboardingFlags.practiceNames || !onboardingFlags.practices) {
      navigate('/onboarding/practices-names');
    } else {
      const practiceFlagInfo = checkPracticeSpecificOnboardingFlags(onboardingFlags.practices, onboardingPaymentType);
      if (practiceFlagInfo) {
        const { practiceId, step } = practiceFlagInfo;

        navigate(`/onboarding/${practiceId}/${step}`);
      } else {
        if (Object.keys(onboardingFlags.practices).length === 1) {
          const practiceId = Object.keys(onboardingFlags.practices)[0];
          const practiceIndex = onboardingFlags.practices[practiceId].index;
          navigate(`/onboarding/${practiceId}/invoice-address`, {
            state: { index: practiceIndex },
          });
        }
      }
    }
  }, [userLoggedIn]);
}

const checkPracticeSpecificOnboardingFlags = (
  practiceSpecificFlags: PracticesOnboardingFlags,
  onboardingPaymentType?: 'same' | 'different',
) => {
  const sortedFlags = Object.entries(practiceSpecificFlags).sort((a, b) => a[1].index - b[1].index);
  console.log('sortedFlags', sortedFlags);
  for (const entry of sortedFlags) {
    const [practiceId, practiceFlags] = entry;
    if (!practiceFlags.payoutData) {
      return { practiceId, step: 'practice-data-info' };
    } else if (!practiceFlags.therapists) return { practiceId, step: 'practice-therapists' };
    else if (!practiceFlags.contactPerson) return { practiceId, step: 'practice-contact' };
    else if (!practiceFlags.starterPack) return { practiceId, step: 'practice-starter' };
    else if (!practiceFlags.invoiceData || !practiceFlags.checkout) {
      if (!onboardingPaymentType && sortedFlags.length > 1) {
        return { practiceId, step: 'payment-type' };
      } else if (
        onboardingPaymentType === 'different' ||
        (onboardingPaymentType === 'same' && practiceFlags.index === sortedFlags.length - 1)
      ) {
        return { practiceId, step: 'invoice-address' };
      }
    }
  }

  return undefined;
};
