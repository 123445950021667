import { doc, getDoc } from 'firebase/firestore';
import { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { getUser } from '../../../store/userSlice';
import { PracticePackageInfo } from '../../../types/Practices/PracticePackageInfoType';
import { PracticePayout } from '../../../types/Practices/PracticePayoutType';

export const useHandleTransferStarterPackageData = (practiceId: string, setCurrentStep: (value: number) => void) => {
  const PackageInfo = useLoaderData() as null | PracticePackageInfo;
  const ownerData = useMainStore(getUser);

  const [toggleActive, setToggleActive] = useState(PackageInfo?.onboardingCopy ?? false);
  const [fields, setFieldsInternal] = useState({
    practiceName: PackageInfo?.practiceName ?? '',
    recipientName: PackageInfo?.recipientName ?? '',
    street: PackageInfo?.street ?? '',
    houseNumber: PackageInfo?.houseNumber ?? '',
    cityCode: PackageInfo?.cityCode ?? '',
    city: PackageInfo?.city ?? '',
  });

  const setFields = (newFields: { [key: string]: any }) => {
    setToggleActive(false);
    setFieldsInternal({ ...fields, ...newFields });
  };

  const handleToggle = async () => {
    if (!toggleActive) {
      setToggleActive(true);
      const payoutData = (await getDoc(doc(db, 'practices/' + practiceId + '/documents/payoutData'))).data() as
        | undefined
        | PracticePayout;
      if (payoutData) {
        const payoutAddress = payoutData.payoutAddress;
        setFieldsInternal({
          practiceName: payoutData?.practiceName ?? '',
          recipientName: ownerData?.privateInfo.firstName + ' ' + ownerData?.privateInfo.lastName,
          street: payoutAddress?.street ?? '',
          houseNumber: payoutAddress?.houseNumber ?? '',
          cityCode: payoutAddress?.cityCode ?? '',
          city: payoutAddress?.city ?? '',
        });
      }
    } else {
      setCurrentStep(0);
      setToggleActive(false);
      setFieldsInternal({
        practiceName: '',
        recipientName: '',
        street: '',
        houseNumber: '',
        cityCode: '',
        city: '',
      });
    }
  };

  return { toggleActive, handleToggle, fields, setFields };
};
