import { getChainPracticesAmount } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';

export const useProgressHeight = () => {
  const practices = useMainStore(getChainPracticesAmount);
  const height = 40;
  const baseHeight = height * 3;
  const additionalHeight = height * practices * 5;
  return baseHeight + additionalHeight + 30;
};
