import styled from 'styled-components';
import { GenericSvg, SVGName } from '../../../assets/svgs/GenericSVG';
import { CustomButton } from './CustomButton';

type Props = {
  onClick?: () => void;
  iconName: SVGName;
  stroke?: string;
  secondaryColor?: string;
  size?: number;
};

export const IconButton = (props: Props) => {
  return (
    <StyledCustomButton onClick={props.onClick}>
      <GenericSvg
        name={props.iconName}
        primaryColor={props.stroke}
        secondaryColor={props.secondaryColor}
        size={props.size ?? 30}
      />
    </StyledCustomButton>
  );
};

const StyledCustomButton = styled(CustomButton)`
  height: 48px;
  width: 48px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
