import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { MainButton } from '../../../core/components/buttons/MainButton';
import { TextButton } from '../../../core/components/buttons/TextButton';
import { InfoText } from '../../../core/components/text/InfoText';
import { getInvitationFirstName } from '../../../store/invitationSlice';
import { useMainStore } from '../../../store/mainStore';
import { Body60 } from '../../../styles/textStyles';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { useCheckForInvitationId } from '../utils/checkForInvitation';

export const WelcomeScreen = () => {
  const { hasInvitation } = useCheckForInvitationId();

  const firstName = useMainStore(getInvitationFirstName);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate('../welcome/login', { state: { hasBack: true } });
  };
  const handleRegister = () => {
    navigate('../welcome/registration');
  };

  if (!hasInvitation) {
    return <Navigate to="/welcome/login" replace={true} />;
  }
  return (
    <OnboardingScreenContainer hideBackButton hideProgress>
      <div>
        <InfoText
          headline={t('ONBOARDING-WELCOME-TITLE', { name: firstName })}
          text={[t('ONBOARDING-WELCOME-SUBTITLE')]}
        />
        <StyledMainButton text={t('ONBOARDING-BUTTON-REGISTER-NOW')} onClick={handleRegister} />
        <SignInContainer>
          <Body60>{t('ONBOARDING-WELCOME-ALREADY-REGISTERED')}</Body60>
          <TextButton text={t('ONBOARDING-BUTTON-LOGIN')} onClick={handleLogin} />
        </SignInContainer>
      </div>
    </OnboardingScreenContainer>
  );
};

const StyledMainButton = styled(MainButton)`
  margin-top: 50px;
  width: 100%;
`;

const SignInContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
`;
