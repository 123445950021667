import { colors } from '../../../styles/colors';
import { IconButton } from './IconButton';

type Props = {
  checked: boolean;
  onPress?: () => void;
};

export const RadioButton = (props: Props) => {
  return (
    <IconButton
      iconName={props.checked ? 'CheckBoxRoundActive' : 'CheckBoxRoundInactive'}
      stroke={props.checked ? colors.Primary : colors.Medium}
      onClick={props.onPress}
      size={20}
    />
  );
};
