import { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useMainStore } from '../../../store/mainStore';
import { getUser } from '../../../store/userSlice';
import { PracticeContact } from '../../../types/Practices/PracticeContactType';

export const useHandleOnboardingPracticeContact = (amountPractices: number) => {
  const initialPracticeContactData = useLoaderData() as null | PracticeContact;
  const user = useMainStore(getUser);
  let initialData: any = initialPracticeContactData;
  if (amountPractices === 1 && !initialData) {
    initialData = { ...user?.privateInfo, onboardingCopy: true };
  }
  const [toggleActive, setToggleActive] = useState(initialData?.onboardingCopy ?? false);
  const [fields, setFieldsState] = useState({
    firstName: initialData?.firstName ?? '',
    lastName: initialData?.lastName ?? '',
    email: initialData?.email ?? '',
    phoneNumber: initialData?.phoneNumber ?? '',
  });

  const ownerData = useMainStore(getUser);

  const setFields = (newFields: { [key: string]: any }) => {
    setToggleActive(false);
    setFieldsState({ ...fields, ...newFields });
  };

  const handleToggle = () => {
    if (!toggleActive) {
      setToggleActive(true);
      setFieldsState({
        firstName: ownerData?.privateInfo?.firstName ?? '',
        lastName: ownerData?.privateInfo?.lastName ?? '',
        email: ownerData?.privateInfo.email ?? '',
        phoneNumber: ownerData?.privateInfo?.phoneNumber ?? '',
      });
    } else {
      setToggleActive(false);
      setFieldsState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      });
    }
  };
  return { toggleActive, handleToggle, fields, setFields };
};
