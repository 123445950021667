import { useState } from 'react';
import styled from 'styled-components';
import { IconButton } from '../../../core/components/buttons/IconButton';
import { TextButton } from '../../../core/components/buttons/TextButton';
import { useWindowDimensions } from '../../../core/utils/useWindowDimensions';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { Body, MonoText } from '../../../styles/textStyles';
import { PracticePayoutInfo } from '../../../types/Administration/PracticePayoutType';
import { formatMoney } from '../../onboarding/components/CheckoutCalculatorModal';
import { downloadPayoutPDF, openPDFInNewTab } from '../utils/downloadPayoutPDF';

type Props = {
  data: PracticePayoutInfo;
};

export const PayoutCell = ({ data }: Props) => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { isMobileWidth } = useWindowDimensions();
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const handleDownload = async () => {
    setIsDownloadLoading(true);
    try {
      await downloadPayoutPDF(data);
    } catch (error) {
      console.error('Error downloading the PDF:', error);
      setEventIndicator('error', 'Fehler beim Herunterladen');
    } finally {
      setIsDownloadLoading(false);
      setEventIndicator('success', 'Download erfolgreich');
    }
  };

  return (
    <Container>
      <InformationContainer>
        <MonthName>{'Gutschrift ' + new Date(data.date).toLocaleString('de', { month: 'long' })}</MonthName>
        {!isMobileWidth && (
          <>
            <MonoText>
              {new Date(data.date).toLocaleDateString('de', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </MonoText>
            <MonoText>{formatMoney(data.payoutEuroBrutto)}</MonoText>
          </>
        )}
      </InformationContainer>
      <ButtonContainer>
        <TextButton onClick={handleDownload} text={'Download (PDF)'} loading={isDownloadLoading} />
        <IconButton
          iconName="OpenPDF"
          size={36}
          onClick={() => openPDFInNewTab(data)}
          stroke={colors.White}
          secondaryColor={colors.Medium}
        />
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid ${colors.Light};
  padding-bottom: 20px;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
`;

const MonthName = styled(Body)`
  width: 150px;
  color: ${colors.Medium};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
