import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { EmailVerificationScreen } from './features/onboarding/screens/EmailVerificationScreen.tsx';
import { LoginScreen } from './features/onboarding/screens/LoginScreen.tsx';
import { OnboardingCheckoutScreen } from './features/onboarding/screens/OnboardingCheckoutScreen.tsx';
import { OnboardingFeedbackScreen } from './features/onboarding/screens/OnboardingFeedbackScreen.tsx';
import { OnboardingPaymentTypeScreen } from './features/onboarding/screens/OnboardingPaymentTypeScreen.tsx';
import {
  OnboardingPracticeInvoiceAddressScreen,
  practiceInvoiceDataLoader,
} from './features/onboarding/screens/OnboardingPracticeInvoiceAddressScreen.tsx';
import { OverviewScreen } from './features/overview/screens/OverviewScreen.tsx';

import { usePracticeChainConnect } from './core/utils/connectFirestore/usePracticeChainConnect.ts';
import { useRefreshOutdatedApp } from './core/utils/connectFirestore/useRefreshOutdatedApp.ts';
import { useUserConnect } from './core/utils/connectFirestore/useUserConnect.ts';
import { CheckoutCalculatorModal } from './features/onboarding/components/CheckoutCalculatorModal.tsx';
import { EmailChangeScreen } from './features/onboarding/screens/EmailChangeScreen.tsx';
import { OnboardingDefaultScreen } from './features/onboarding/screens/OnboardingDefaultScreen.tsx';
import {
  OnboardingPracticeContactScreen,
  practiceContactDataLoader,
} from './features/onboarding/screens/OnboardingPracticeContactScreen.tsx';
import { OnboardingPracticeDataInfoScreen } from './features/onboarding/screens/OnboardingPracticeDataInfoScreen.tsx';
import { OnboardingPracticeNamesScreen } from './features/onboarding/screens/OnboardingPracticeNamesScreen.tsx';
import {
  OnboardingPracticePayoutDataScreen,
  practicePayoutDataLoader,
} from './features/onboarding/screens/OnboardingPracticePayoutDataScreen.tsx';
import {
  OnboardingPracticeStarterPackageScreen,
  practiceStarterPackageLoader,
} from './features/onboarding/screens/OnboardingPracticeStarterPackageScreen.tsx';
import {
  OnboardingPracticeTherapistsScreen,
  practiceTherapistsDataLoader,
} from './features/onboarding/screens/OnboardingPracticeTherapistsScreen.tsx';
import { OnboardingReferralScreen } from './features/onboarding/screens/OnboardingReferralScreen.tsx';
import { OnboardingUserBasicInfoScreen } from './features/onboarding/screens/OnboardingUserBasicInfoScreen.tsx';
import { OnboardingVerificationScreen } from './features/onboarding/screens/OnboardingVerificationScreen.tsx';
import { PasswordResetScreen } from './features/onboarding/screens/PasswordResetScreen.tsx';
import { PaymentStatusScreen } from './features/onboarding/screens/PaymentStatusScreen.tsx';
import { RegistrationScreen } from './features/onboarding/screens/RegistrationScreen';
import { WelcomeScreen } from './features/onboarding/screens/WelcomeScreen.tsx';
import { useLoadInvitationData } from './features/onboarding/utils/useLoadInvitationData.ts';
import { WebAppInstallationInstructionScreen } from './features/overview/screens/WebAppInstallationInstructionScreen.tsx';
import { AppMailVerificationScreen } from './features/patientAppVerification/AppMailVerificationScreen.tsx';
import { ErrorOverlay } from './features/sentry/screens/ErrorOverlay.tsx';
import { SentryRouteErrorFallback } from './features/sentry/screens/SentryRouteErrorFallback.tsx';
import { ChangeEmailModal } from './features/settings/components/ChangeEmailModal.tsx';
import { ChangePasswordModal } from './features/settings/components/ChangePasswordModal.tsx';
import { TherapistSettingsAddModal } from './features/settings/components/TherapistSettingsAddModal.tsx';
import { TherapistSettingsDisableModal } from './features/settings/components/TherapistSettingsDisableModal.tsx';
import { TherapistSettingsRenameModal } from './features/settings/components/TherapistSettingsRenameModal.tsx';
import { PersonalSettingsScreen } from './features/settings/PersonalSettingsScreen.tsx';
import { PracticeSettingsInvoiceScreen } from './features/settings/PracticeSettingsInvoiceScreen.tsx';
import { PracticeSettingsPayoutScreen } from './features/settings/PracticeSettingsPayoutScreen.tsx';
import { PracticeSettingsTeamScreen } from './features/settings/PracticeSettingsTeamScreen.tsx';
import './firebaseConfig.ts';
import { DrawerLayout } from './navigation/DrawerLayout.tsx';
import { InAppLayout } from './navigation/InAppLayout.tsx';
import { MainLayout } from './navigation/MainLayout.tsx';
import { OnboardingLayout } from './navigation/OnboardingLayout';
import { PaymentLayout } from './navigation/PaymentLayout.tsx';
import { PracticeSettingsLayout } from './navigation/PracticeSettingsLayout.tsx';
import { useIntercomInit } from './navigation/useIntercomInit.tsx';
import { useDetectEmailChange } from './navigation/utils/useDetectEmailChange.tsx';
import { useHandleAuthState } from './navigation/utils/useHandleAuthState.tsx';
import { WelcomeLayout } from './navigation/WelcomeLayout';
import { WithoutDrawerLayout } from './navigation/WithoutDrawerLayout.tsx';
import initI18n from './translations/i18n.config';

function App() {
  initI18n();
  useHandleAuthState();
  useDetectEmailChange();
  useIntercomInit();
  useUserConnect();
  usePracticeChainConnect();
  useLoadInvitationData();
  useRefreshOutdatedApp();
  // useStartPosthog();

  const NewPracticePaths = {
    path: ':practiceId',
    children: [
      { path: 'practice-data-info', element: <OnboardingPracticeDataInfoScreen /> },
      {
        path: 'practice-payout-data',
        element: <OnboardingPracticePayoutDataScreen />,
        loader: practicePayoutDataLoader,
      },
      {
        path: 'practice-therapists',
        element: <OnboardingPracticeTherapistsScreen />,
        loader: practiceTherapistsDataLoader,
      },
      {
        path: 'practice-contact',
        element: <OnboardingPracticeContactScreen />,
        loader: practiceContactDataLoader,
      },
      {
        path: 'practice-starter',
        element: <OnboardingPracticeStarterPackageScreen />,
        loader: practiceStarterPackageLoader,
      },
      {
        element: <PaymentLayout />,
        children: [
          { path: 'payment-type', element: <OnboardingPaymentTypeScreen /> },
          {
            path: 'invoice-address',
            element: <OnboardingPracticeInvoiceAddressScreen />,
            loader: practiceInvoiceDataLoader,
          },
          {
            path: 'checkout',
            element: <OnboardingCheckoutScreen />,
            children: [
              {
                path: 'calculator',
                element: <CheckoutCalculatorModal />,
              },
            ],
          },
          // { path: 'contract', element: <OnboardingContractScreen />, loader: practiceContractLoader },
        ],
      },
      { path: 'payment-status', element: <PaymentStatusScreen /> },
    ],
  };

  const OnboardingPaths = {
    path: 'onboarding',
    element: <OnboardingLayout />,
    children: [
      {
        index: true,
        element: <OnboardingDefaultScreen />,
      },
      { path: 'verification', element: <OnboardingVerificationScreen /> },
      { path: 'basic-info', element: <OnboardingUserBasicInfoScreen /> },
      { path: 'referral', element: <OnboardingReferralScreen /> },
      { path: 'practices-names', element: <OnboardingPracticeNamesScreen /> },
      NewPracticePaths,
      { path: 'feedback', element: <OnboardingFeedbackScreen /> },
    ],
  };

  const WelcomePaths = {
    path: 'welcome',
    element: <WelcomeLayout />,
    children: [
      { path: '', element: <WelcomeScreen /> },
      { path: 'registration', element: <RegistrationScreen /> },
      { path: 'login', element: <LoginScreen /> },
      { path: 'password-reset', element: <PasswordResetScreen /> },
    ],
  };

  const PracticeSettingsPath = {
    path: 'practice-settings/:practiceId',
    element: <PracticeSettingsLayout />,
    children: [
      {
        path: 'team',
        element: <PracticeSettingsTeamScreen />,
        children: [
          { path: 'add-therapist', element: <TherapistSettingsAddModal /> },
          { path: 'rename-therapist', element: <TherapistSettingsRenameModal /> },
          { path: 'disable-therapist', element: <TherapistSettingsDisableModal /> },
        ],
      },
      { path: 'payout', element: <PracticeSettingsPayoutScreen /> },
      { path: 'invoice', element: <PracticeSettingsInvoiceScreen /> },
      { path: '', element: <Navigate to="team" /> },
    ],
  };

  const MainPaths = {
    path: '/',
    children: [
      {
        element: <WithoutDrawerLayout />,
        children: [
          { element: <WebAppInstallationInstructionScreen />, path: 'webapp-installation-instruction' },
          {
            path: 'new-practice',
            children: [{ path: 'practices-names', element: <OnboardingPracticeNamesScreen /> }, NewPracticePaths],
          },
        ],
      },
      {
        element: <DrawerLayout />,
        children: [
          { path: '', element: <Navigate to="/overview" replace={true} /> },
          { path: 'overview', element: <OverviewScreen />, loader: practiceStarterPackageLoader },
          {
            path: 'personal-data',
            element: <PersonalSettingsScreen />,
            children: [
              { path: 'change-email', element: <ChangeEmailModal /> },
              { path: 'change-password', element: <ChangePasswordModal /> },
            ],
          },
          PracticeSettingsPath,
        ],
      },
    ],
  };

  const InAppPaths = {
    element: <InAppLayout />,
    children: [MainPaths, OnboardingPaths],
  };

  const router = createBrowserRouter([
    {
      errorElement: <SentryRouteErrorFallback />,
      element: <MainLayout />,
      children: [
        { path: 'verification', element: <EmailVerificationScreen /> },
        { path: 'app-verification', element: <AppMailVerificationScreen /> },
        { path: 'change-verification', element: <EmailChangeScreen /> },
        WelcomePaths,
        InAppPaths,
        { path: '*', element: <ErrorOverlay type={'404'} /> },
      ],
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<ErrorOverlay type={'404'} />} />;
}

export default App;
