import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';

type Props = {
  color?: string;
  size?: number;
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingSpinner = ({ color = colors.White, size = 24 }: Props) => {
  return (
    <Container>
      <ClipLoader
        color={color}
        loading={true}
        size={size}
        aria-label="Loading Spinner"
        data-testid="loader"
        speedMultiplier={0.55}
      />
    </Container>
  );
};
