import styled from 'styled-components';
import { IconButton } from '../../core/components/buttons/IconButton';
import { DESKTOP_BREAKPOINT } from '../../styles/breakpoints';

type Props = {
  onBackClick?: () => void;
  onCloseClick?: () => void;
};

export const NavigationHeader: React.FC<Props> = ({ onBackClick, onCloseClick }) => {
  return (
    <Container>
      {onBackClick ? <IconButton iconName={'ArrowLeft'} onClick={onBackClick} size={24} /> : <div />}
      {onCloseClick ? <IconButton iconName={'Close'} onClick={onCloseClick} size={24} /> : <div />}
    </Container>
  );
};

const Container = styled.div`
  z-index: 100;
  position: fixed;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  top: 60px;
  padding: 10px 40px;
  left: 0px;
  right: 0px;
  pointer-events: none;
  > * {
    pointer-events: auto;
  }
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    padding: 10px 20px;
  }
`;
