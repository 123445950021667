import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { NavigationHeader } from '../../../navigation/components/NavigationHeader';
import { DESKTOP_BREAKPOINT } from '../../../styles/breakpoints';
import { colors } from '../../../styles/colors';
import { CheckoutBill } from '../components/CheckoutBill';
import { CheckoutCalculatorButtons } from '../components/CheckoutCalculatorButtons';
import { OnboardingCheckoutForm } from '../components/OnboardingCheckoutForm';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';

const stripe = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

export const OnboardingCheckoutScreen = () => {
  const navigate = useNavigate();
  const [clientSecret, _setClientSecret] = useState(useLocation().state?.clientSecret);
  const { practiceId } = useOnboardingPracticeInfo();

  if (!practiceId) {
    return <Navigate to="../../practices-names" />;
  } else if (!clientSecret) {
    return <Navigate to={`../invoice-address`} />;
  }

  const handleBack = () => {
    navigate('../invoice-address');
  };

  return (
    <Elements
      stripe={stripe}
      options={{
        locale: 'de',
        appearance,
        clientSecret,
      }}
    >
      <NavigationHeader onBackClick={handleBack} />
      <Container>
        <MobileContainer>
          <CheckoutBill />
        </MobileContainer>
        <ContentContainer>
          <CheckoutBill />
          <CheckoutCalculatorButtons />
        </ContentContainer>
        <OnboardingCheckoutForm />
        <MobileContainer>
          <CheckoutCalculatorButtons />
        </MobileContainer>
      </Container>
      <Outlet />
    </Elements>
  );
};

const appearance = {
  theme: 'stripe' as any,
  variables: {
    colorPrimary: colors.Primary,
    colorText: colors.Dark,
  },
};

const Container = styled.div<{ $hideProgress?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 975px;
  justify-content: ${(props) => (props.$hideProgress ? 'center' : 'flex-start')};
  background-color: 'blue';
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    max-width: 640px;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    gap: 40px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  padding: 0 20px;
  width: 100%;
  gap: 50px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    display: none;
  }
`;

const MobileContainer = styled.div`
  display: none;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    display: block;
  }
`;
