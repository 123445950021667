import { Navigate, Outlet } from 'react-router-dom';
import { usePracticeChainConnect } from '../core/utils/connectFirestore/usePracticeChainConnect';
import { useUpdateSentry } from '../features/sentry/utils/useUpdateSentry';
import { useMainStore } from '../store/mainStore';
import { getUserIsLoggedIn } from '../store/userSlice';

export const InAppLayout = () => {
  const userLoggedIn = useMainStore(getUserIsLoggedIn);
  usePracticeChainConnect();
  useUpdateSentry();

  if (!userLoggedIn) {
    return <Navigate to="/welcome" />;
  }

  return <Outlet />;
};
