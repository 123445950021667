import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonsAccordionComponent } from '../../../core/components/ButtonsAccordionComponent';
import { useMainStore } from '../../../store/mainStore';
import { Therapist } from '../../../types/Practices/TherapistType';

type Props = {
  therapist: Therapist;
};

export const TherapistCell = ({ therapist }: Props) => {
  const practiceId = useParams().practiceId;
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const navigate = useNavigate();
  const [copyLinkLoading, setCopyLinkLoading] = useState(false);

  const changeName = () => {
    navigate('rename-therapist', { state: { therapist } });
  };
  const disableTherapist = () => {
    navigate('disable-therapist', { state: { therapist } });
  };

  const practiceDomain =
    import.meta.env.VITE_ENV === 'production'
      ? 'https://app.physiofit.app'
      : 'https://io-physiofit-development.web.app';
  const therapistUrl = `${practiceDomain}/newPatient?pId=${practiceId}&tId=${therapist.id}`;
  const copyLink = () => {
    try {
      if (!practiceId) {
        throw new Error('No practice id found');
      }

      setCopyLinkLoading(true);
      setTimeout(() => {
        setCopyLinkLoading(false);
      }, 1000);
      navigator.clipboard.writeText(therapistUrl);
      setEventIndicator('success', 'Therapeuten Link kopiert');
    } catch (error) {
      console.error('Error copying link: ', error);
      setEventIndicator('error', 'Fehler beim Kopieren des Links');
    }
  };

  const openLink = () => {
    window.open(therapistUrl, '_blank');
  };

  const activeItems = [
    {
      title: 'Name Ändern',
      onClick: changeName,
    },
    {
      title: 'Persönlichen Physiofit Link kopieren',
      onClick: copyLink,
      loading: copyLinkLoading,
    },
    {
      title: 'Persönlichen Physiofit Link öffnen',
      onClick: openLink,
    },
    {
      title: 'Therapeut deaktivieren',
      onClick: disableTherapist,
      warning: true,
    },
  ];

  const inactiveItems = [
    {
      title: 'Therapeut aktivieren',
      onClick: disableTherapist,
    },
  ];
  return (
    <ButtonsAccordionComponent
      title={therapist.firstName + ' ' + therapist.lastName}
      items={therapist.inactive ? inactiveItems : activeItems}
    />
  );
};
