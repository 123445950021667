import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { H2 } from '../../styles/textStyles';
import { MainScreenContainer } from '../overview/components/MainScreenComponent';
import { ManagePracticesComponent } from './components/ManagePracticesComponent';
import { PersonalDataComponent } from './components/PersonalDataComponent';
import { ResetCredentialsComponent } from './components/ResetCredentialsComponent';

export const PersonalSettingsScreen = () => {
  const { t } = useTranslation();

  return (
    <>
      <MainScreenContainer>
        <Container>
          <StyledH2>{t('PERSONAL-SETTINGS-SCREEN-TITLE')}</StyledH2>
          <PersonalDataComponent />
          <ResetCredentialsComponent />
          <ManagePracticesComponent />
        </Container>
      </MainScreenContainer>
      <Outlet />
    </>
  );
};

const StyledH2 = styled(H2)`
  color: ${colors.Dark};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 200px;
`;
