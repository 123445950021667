import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import posthog from 'posthog-js';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ProgressButton } from '../../../core/components/buttons/ProgressButton';
import { RadioButton } from '../../../core/components/buttons/RadioButton';
import { hexWithOpacity } from '../../../core/utils/hexWithOpacity';
import { getIsAddNewPractice } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { DESKTOP_BREAKPOINT } from '../../../styles/breakpoints';
import { colors } from '../../../styles/colors';
import { Small } from '../../../styles/textStyles';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';

export const OnboardingCheckoutForm = () => {
  const invoiceName = useLocation().state?.invoiceName;
  const stripe = useStripe();
  const elements = useElements();
  const currentDomain = window.location.origin;
  const isAddNewPractice = useMainStore(getIsAddNewPractice);
  const { practiceId } = useOnboardingPracticeInfo();

  const [paymentDataComplete, setPaymentDataComplete] = useState(false);
  const [elementsReady, setElementsReady] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [confirmedContract, setConfirmedContract] = useState(false);

  const handleSubmit = async () => {
    try {
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      setIsLoading(true);

      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url:
            currentDomain + `/${isAddNewPractice ? 'new-practice' : 'onboarding'}/${practiceId}/payment-status`,
        },
      });
      posthog.capture('onboarding_step_checkout_completed');

      if (result.error) {
        console.log(JSON.stringify(result));
        // Show error to your customer
        setMessage(result.error.message || 'An unexpected error occurred.');
      } else {
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setMessage('An unexpected error occurred.');
    }
  };

  let currentStep = 0;
  if (paymentDataComplete) {
    currentStep += 1;
  }
  if (confirmedContract) {
    currentStep += 1;
  }
  return (
    <CheckoutContainer>
      <PaymentElement
        onReady={() => setElementsReady(true)}
        onChange={(event) => {
          setPaymentDataComplete(event.complete);
        }}
        options={{
          layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: false,
            spacedAccordionItems: false,
          },
          business: { name: 'Digital Training and Therapy GmbH' },
        }}
      />
      {message && <div id="payment-message">{message}</div>}
      {elementsReady && (
        <>
          <ContractContainer>
            <RadioButton checked={confirmedContract} onPress={() => setConfirmedContract(!confirmedContract)} />
            <StyledSmall>
              {'Hiermit bestätige ich, dass ich mit meiner Zahlung dem '}
              <StyledLink
                href="https://physiofitapp.notion.site/Vertrag-und-Allgemeine-Gesch-ftsbedingungen-AGB-fedeef5a26c346829e5e86b42938e344"
                target="_blank"
                rel="noopener noreferrer"
              >
                {'Dienstleistungsvertrag'}
              </StyledLink>
              {` zwischen der Digital Training and GmbH und ${invoiceName} zustimme.`}
            </StyledSmall>
          </ContractContainer>
          <ProgressButton
            onClick={handleSubmit}
            text="Zahlungspflichtig bestellen"
            currentStep={currentStep}
            numberOfSteps={2}
            disabled={!paymentDataComplete || isLoading}
          />
        </>
      )}
    </CheckoutContainer>
  );
};

const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  width: 100%;
  gap: 20px;
  padding-top: 40px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    max-width: none;
    padding-top: 0;
  }
`;

const ContractContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-left: 10px;
  align-items: center;
`;

const StyledSmall = styled(Small)`
  color: ${hexWithOpacity(colors.Medium, 0.65)};
  text-align: left;
`;

const StyledLink = styled.a`
  text-decoration: underline;
  color: ${colors.Dark};
`;
