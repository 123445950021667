import { getFunctions, httpsCallable } from 'firebase/functions';

export const sendPasswordResetMail = async (email: string) => {
  const functions = getFunctions();

  const call = httpsCallable(functions, 'sendDashboardPasswordReset');
  const callableReturnMessage = await call({
    language: 'de',
    userMail: email,
  });
  return callableReturnMessage.data;
};
